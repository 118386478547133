import React from "react";
import {
  FaReact,
  FaHtml5,
  FaMobileAlt,
  FaHeadset,
  FaAd,
  FaSearchengin,
  FaBlogger,
  FaBlog,
  FaYoutubeSquare,
} from "react-icons/fa";
export default [
  {
    id: 1,
    slug: "become a front end developer",
    icon: <FaReact className="service-icon" />,
    title: "front end developer",
    text: `everything you need to know become an efficient front end developer`,
  },
  {
    id: 2,
    slug: "become a back end developer",
    icon: <FaHtml5 className="service-icon" />,
    title: "back end developer",
    text: `you have all the tools needed to be a back end developer`,
  },
  {
    id: 3,
    slug: "make a responsive website faster",
    icon: <FaMobileAlt className="service-icon" />,
    title: "responsive design",
    text: `learn how to make a better users experience`,
  },
  {
    id: 4,
    slug: "submit your concern on the comment or by email",
    icon: <FaHeadset className="service-icon" />,
    title: "strong support",
    text: `you can contact us by email for any concerns.
    In addition, we have some affiliate links that point to a best courses 
    to become an efficient developer`,
  },
  {
    id: 5,
    slug: "learn about how to make money online as developer",
    icon: <FaAd className="service-icon" />,
    title: "affiliate marketing",
    text: `I understand how Facebook ads and Google ads work,and have built so many projects. In Addition, 
    I know how to use Amazon, Ebay, Clickbank to sell or promote product. In general, I understand how marketing works  `,
  },
  {
    id: 6,
    slug: "learn search engine optimization",
    icon: <FaSearchengin className="service-icon" />,
    title: "Search Engine Optimization",
    text: `I know how to make website have authority even if it is the new site.
    there are lots of tricks that I have learnt when I was sitting up most of my busness sites.
    I would definitely bring lots of values  `,
  },
  {
    id: 7,
    slug: "read all the blog post",
    icon: <FaBlogger className="service-icon" />,
    title: "blog",
    text: `it is important to read a blog post to find motivation for people 
    who have been or are going through a situation you currently in or you 
    can learn how the tech industry is trending`,
  },
  {
    id: 8,
    slug: "read all tutorials",
    icon: <FaBlog className="service-icon" />,
    title: "tutorial",
    text: `though most people like video rather than a writing 
    tutorials, our team has dedicaded lots of efforts to write a very 
    quick and straight to the point tutorials to help you have a better experience`,
  },
  {
    id: 9,
    slug: "watch video",
    icon: <FaYoutubeSquare className="service-icon" />,
    title: "video",
    text: `There are tons of real world projects been built from scratch to help
    you understand programming from ground up`,
  },
];
