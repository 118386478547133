import React, { useState, useContext, useEffect } from "react";
import AddChildComment from "./AddChildComment";
import EditSingleParentComment from "./EditSingleParentComment";
import ShareSingleParentComment from "./ShareSingleParentComment";
import DeleteSingleParentComment from "./DeleteSingleParentComment";
import ChildComment from "./ChildComment";
import { blogContext } from "../App";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { Parser } from "simple-text-parser";
import urlRegex from "url-regex";

function SingleParentComment({
  username,
  image,
  category,
  comment,
  created_at,
  id,
  email,
  handleDeleteComment,
  isPostDeleted,
  handlePostDeleted,
  title,
  slug,
}) {
  let parser = new Parser();
  const re = urlRegex();
  parser = parser.addRule(re, (url) => {
    return `<a href= ${url} target="_blank" rel="noopener noreferrer">${url}</a>`;
  });
  const { user, isAuthenticated, loginWithRedirect } = useAuth0();
  const { comments, setComment, updateTime, calculateRelativeTime } =
    useContext(blogContext);

  const [relativeTime, setRelativeTime] = useState("");

  useEffect(() => {
    if (created_at) {
      // Parse the provided created_at timestamp in UTC format
      const timestamp = new Date(created_at);

      // Calculate the relative time based on your local timezone
      setRelativeTime(calculateRelativeTime(timestamp));

      // Update the relative time every 10 seconds
      const intervalId = setInterval(() => {
        setRelativeTime(calculateRelativeTime(timestamp));
      }, 10000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [created_at]);

  const currentUserEmail = email;
  const canEdit = isAuthenticated && currentUserEmail === user.email;
  const canReply = username;

  let time = new Date(created_at).toLocaleDateString("en-US").split("/");
  let currentTime = `${updateTime(time[0])} ${time[1]}, ${time[2]}`;
  const [visibility, setVisibility] = useState(false);
  const [deleteVisibility, setDeleteVisibility] = useState(false);
  const [shareVisibility, setShareVisibility] = useState(false);
  const [editComment, setEditComment] = useState(false);

  const { replyComment } = useContext(blogContext);
  const repliesComment = replyComment(title, id);

  const handleReplyComment = () => {
    // Store the current path in session storage
    sessionStorage.setItem("redirectPath", window.location.pathname);
    setVisibility((prev) => {
      return !prev;
    });
  };
  const handleShareComment = () => {
    // Store the current path in session storage
    sessionStorage.setItem("redirectPath", window.location.pathname);
    setShareVisibility((prev) => {
      return !prev;
    });
  };
  const handlePoPupDeleteComment = () => {
    // Store the current path in session storage
    sessionStorage.setItem("redirectPath", window.location.pathname);
    setDeleteVisibility((prev) => {
      return !prev;
    });
  };

  const handleLogin = () => {
    // Store the current path in session storage
    sessionStorage.setItem("redirectPath", window.location.pathname);

    // Redirect to the login page
    loginWithRedirect();
  };

  const handleLikeComment = (parentId) => {
    // Store the current path in session storage
    sessionStorage.setItem("redirectPath", window.location.pathname);
    let singleLike = comments.find(
      (comment) =>
        comment.parentId === parentId &&
        comment.email === user.email &&
        comment.comment === null &&
        comment.category === category
    );
    if (singleLike === undefined) {
      axios
        .post("http://localhost:1337/Comments", {
          username: user.given_name || user.username || username.name,
          email: user.email,
          parentId: parentId,
          title: title,
          comment: null,
          image: user.picture,
          like: 1,
          category: category,
        })
        .then(() => {
          axios
            .get("http://localhost:1337/Comments")
            .then((response) => {
              return response.data;
            })
            .then((data) => {
              setComment(data);
            });
        });
    } else {
      const { id, like } = comments.find(
        (comment) =>
          comment.parentId === parentId &&
          comment.comment === null &&
          comment.email === user.email &&
          comment.category === category
      );
      axios
        .put(`http://localhost:1337/Comments/${id}`, {
          like: (parseInt(like) === 0 && 1) || (parseInt(like) === 1 && 0),
        })
        .then(() => {
          axios
            .get("http://localhost:1337/Comments")
            .then((response) => {
              return response.data;
            })
            .then((data) => {
              setComment(data);
            })
            .catch((error) => {
              return error;
            });
        })
        .catch((error) => {
          return error;
        });
    }
  };

  const allLikes = comments
    .filter((comment) => {
      return (
        comment.parentId === id &&
        comment.comment === null &&
        comment.category === category
      );
    })
    .reduce((acc, currentValue) => {
      return acc + currentValue.like;
    }, 0);
  const handleEditComment = () => {
    setEditComment((prev) => {
      return !prev;
    });
  };
  return (
    <div className="global-container-comments-user">
      <div className="global-container-comments-user__container_profile_author_date">
        <div className="global-container-comments-user__container_profile">
          {" "}
          <img src={image} alt={username} />
        </div>
        <div className="global-container-comments-user__container_author_date">
          <span>
            <b style={{ fontWeight: "bold" }}> @{username}</b>
          </span>
          {/* <span>{currentTime} </span> */}
          <span style={{ fontSize: "14px" }}>{relativeTime}</span>
        </div>
      </div>
      <div className="global-container-comments-user_comments">
        <p dangerouslySetInnerHTML={{ __html: parser.render(comment) }} />
        {/* <p>{comment}</p> */}
      </div>
      <div className="global-container-comments-user_like_reply_edit_delete_share">
        {!isAuthenticated ? (
          <button onClick={handleLogin} className="btn-primary">
            👍 {allLikes}
          </button>
        ) : (
          <button onClick={() => handleLikeComment(id)} className="btn-primary">
            👍 {allLikes}
          </button>
        )}
        {isAuthenticated && canReply !== undefined ? (
          <button onClick={handleReplyComment} className="btn-primary">
            reply
          </button>
        ) : (
          <button onClick={handleLogin} className="btn-primary">
            reply
          </button>
        )}

        {isAuthenticated ? (
          canEdit ? (
            <button onClick={handleEditComment} className="btn-primary">
              edit
            </button>
          ) : (
            ""
          )
        ) : (
          isAuthenticated && (
            <button onClick={handleLogin} className="btn-primary">
              edit
            </button>
          )
        )}

        {isAuthenticated ? (
          canEdit ? (
            <button onClick={handlePoPupDeleteComment} className="btn-primary">
              delete
            </button>
          ) : (
            ""
          )
        ) : (
          isAuthenticated && (
            <button onClick={handleLogin} className="btn-primary">
              delete
            </button>
          )
        )}

        {!isAuthenticated ? (
          <button onClick={handleLogin} className="btn-primary">
            {" "}
            share
          </button>
        ) : (
          <>
            <button
              onClick={() => handleShareComment(id)}
              className="btn-primary"
            >
              {" "}
              share
            </button>
          </>
        )}
      </div>
      <div className="global-container-comments-user__reply">
        {repliesComment.map((replyComment) => {
          const { id } = replyComment;
          return (
            <ChildComment
              key={id}
              {...replyComment}
              user={user}
              isAuthenticated={isAuthenticated}
              title={title}
              slug={slug}
              handleReplyComment={handleReplyComment}
              category={category}
              visible={visibility}
              type="comment"
              handleShareComment={handleShareComment}
              handleDeleteComment={handleDeleteComment}
              isPostDeleted={isPostDeleted}
              handlePoPupDeleteComment={handlePoPupDeleteComment}
            />
          );
        })}
      </div>
      <EditSingleParentComment
        parentId={id}
        title={title}
        slug={slug}
        comment={comment}
        handleEditComment={handleEditComment}
        category={category}
        editComment={editComment}
      />
      <ShareSingleParentComment
        handleShareComment={handleShareComment}
        shareVisibility={shareVisibility}
      />
      <DeleteSingleParentComment
        handlePoPupDeleteComment={handlePoPupDeleteComment}
        deleteVisibility={deleteVisibility}
        handleDeleteComment={handleDeleteComment}
        id={id}
        isPostDeleted={isPostDeleted}
        handlePostDeleted={handlePostDeleted}
        comments={comment}
        type="comment"
      />

      <AddChildComment
        handleReplyComment={handleReplyComment}
        visible={visibility}
        setVisible={setVisibility}
        parentId={id}
        category={category}
        title={title}
        slug={slug}
      />
    </div>
  );
}

export default SingleParentComment;
