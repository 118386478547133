import React from "react";
import uuid from "react-uuid";
import img1 from "../assets/SociauMedia/facebook.jpg";
import img2 from "../assets/SociauMedia/github.jpg";
import img3 from "../assets/SociauMedia/homeis.jpg";
import img4 from "../assets/SociauMedia/instagram.jpg";
import img5 from "../assets/SociauMedia/twitter.jpg";
import img6 from "../assets/SociauMedia/linkedin.jpg";
import img7 from "../assets/SociauMedia/youtube.jfif";

const data = [
  {
    id: uuid(),
    url: "https://www.facebook.com/groups/546149442825819/",
    name: "facebook",
    img: img1,
  },
  {
    id: uuid(),
    url: "https://github.com/fono97",
    name: "github",
    img: img2,
  },
  {
    id: uuid(),
    url: "https://www.homeis.com/fr-africans-in-usa/virginia/profile/5e894f9571fe3100185662b2",
    name: "homeis",
    img: img3,
  },
  {
    id: uuid(),
    url: "https://www.instagram.com/web_dev_advice/",
    name: "instagram",
    img: img4,
  },
  {
    id: uuid(),
    url: "https://twitter.com/FonoGabriel",
    name: "twitter",
    img: img5,
  },
  {
    id: uuid(),
    url: "https://www.linkedin.com/in/gabriel-fono/",
    name: "linkdin",
    img: img6,
  },
  {
    id: uuid(),
    url: "https://www.youtube.com/channel/UCosXzuWsan-tSsbV2hVdgYQ/",
    name: "youtube",
    img: img7,
  },
];
const sociauxLogo = () => {
  return data.map(({ id, name, img, url }) => {
    return (
      <a href={url} key={id} target="_blank">
        <div className="container-image-link">
          <img src={img} alt={name} />
        </div>
      </a>
    );
  });
};

const SociauxLinks = (props) => {
  return <div className="container-sociaux-links">{sociauxLogo()}</div>;
};

export default SociauxLinks;
{
  data.map((item) => {
    return (
      <a href={item.url} key={item.id}>
        <div className="container-image-link">
          <img src={item.img} alt={item.name} loading="lazy" />
        </div>
      </a>
    );
  });
}
