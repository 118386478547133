import React from "react";
import { Link } from "react-router-dom";

const QuickLinks = () => {
  const handleClick = (event) => {
    event.preventDefault();
  };
  return (
    <div>
      <h4>quick links</h4>
      <ul>
        <li>
          <Link onClick={handleClick} to="">
            About us
          </Link>
        </li>
        <li>
          <Link onClick={handleClick} to="">
            service
          </Link>
        </li>
        <li>
          <Link onClick={handleClick} to="">
            testimonials
          </Link>
        </li>
        <li>
          <Link onClick={handleClick} to="">
            contact
          </Link>
        </li>
        <li>
          <Link onClick={handleClick} to="">
            career
          </Link>
        </li>
        <li>
          <Link onClick={handleClick} to="">
            become an affiliate
          </Link>
        </li>
        <li>
          <Link onClick={handleClick} to="">
            see all contributors
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default QuickLinks;
