import React from "react";
// import Title from "../components/Title";
import img from "../assets/responsive.jpg";
import img1 from "../assets/facebook.jpg";

import descriptions from "../constant/descriptions";

const Responsive = ({ title }) => {
  const data = descriptions.map((item) => {
    return <li key={item.id}> {item.description}</li>;
  });
  return (
    <section className="section">
      {/* <Title title={title} /> */}
      <div className="responsive-article">
        <div>
          <h2> Why Responsive Design is Important for Business?</h2>
          <ul>{data}</ul>
        </div>
        {/* <img src={img1} className='desktop-version' /> */}
        <img src={img} className="mobile-version" alt="mobile-version375" />
        <img src={img1} alt="mobile-version414" />
      </div>
    </section>
  );
};

export default Responsive;
