import React, { useContext } from "react";
import Title from "./Title";
import Price from "./Price";

const Prices = (props) => {
  const { prices, home } = props;

  const { title } = props;
  return (
    <section className="section">
      <Title title={title} specificTitle />
      <h4
        style={{
          fontWeight: "600",
          fontSize: "2rem",
          margin: "1.2rem 0",
        }}
        // className="price-description "
      >
        🎒 Our Upcoming Course with Industry Standard
      </h4>
      <p>
        We are building a course to help people switch career either in Software
        engineering and cyber security.
      </p>

      <div
        className={
          home ? "section-center section-center-home-page" : "section-center"
        }
      >
        {prices.map((price, index) => {
          return <Price key={price.id} {...price} id={index} />;
        })}
      </div>
    </section>
  );
};

export default Prices;
