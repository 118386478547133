import React from "react";
import SociauxLinks from "../constant/SociauxLinks";

function BloggerProfile(props) {
  const {
    bloggerName,
    BloggerTitle,
    bloggerDescription,
    bloggerPhoto,
    bloggerFacebookLink,
    bloggerInstagramLink,
    bloggerYoutubeLink,
    bloggerTwitterLink,
    bloggerGithubLink,
    bloggerPersonalSiteLink,
    bloggerProfileEnabled,
  } = props;

  return (
    <div className="section__blogging__aside_author">
      <div className="section__blogging__aside_author_container">
        <div className="section__blogging__aside_author_container_author">
          <img src={bloggerPhoto.url} alt="founder photo profile" />
        </div>
        <div className="section__blogging__aside_author_container_author_title">
          <span>{bloggerName} </span>
          <span style={{ fontWeight: "300" }}>{BloggerTitle}</span>
          <a
            style={{ color: "#007BFF", textDecoration: "none" }}
            href={bloggerPersonalSiteLink}
          >
            {bloggerPersonalSiteLink}
          </a>
        </div>
      </div>

      <p>{bloggerDescription}</p>
      <h5>Follow {bloggerName}</h5>
      <div className="section__blogging__aside_author_socialLinks_position">
        <SociauxLinks
          bloggerFacebookLink={bloggerFacebookLink}
          bloggerInstagramLink={bloggerInstagramLink}
          bloggerYoutubeLink={bloggerYoutubeLink}
          bloggerTwitterLink={bloggerTwitterLink}
          bloggerGithubLink={bloggerGithubLink}
          bloggerPersonalSiteLink={bloggerPersonalSiteLink}
        />
      </div>
    </div>
  );
}

export default BloggerProfile;
