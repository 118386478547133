import React, { useEffect, useRef } from "react";
import Share from "../components/Share";
function ShareSingleParentComment(props) {
  const { shareVisibility, handleShareComment, id } = props;

  const globalContainer = useRef();
  const closeButton = useRef();

  const closePopup = useRef();
  useEffect(() => {
    if (shareVisibility) {
      closeButton.current.addEventListener("click", () =>
        handleShareComment(id)
      );
    }
    return () => {
      closeButton.current.removeEventListener("click", () =>
        handleShareComment(id)
      );
    };
  }, [shareVisibility]);
  const body = document.body;
  return (
    <div
      ref={globalContainer}
      className={
        shareVisibility
          ? `global-container-signup-form-users global-container-signup-form-users-show     ${(body.style.overflow =
              "hidden")}`
          : `global-container-signup-form-users global-container-signup-form-users-hide  ${(body.style.overflow =
              "scroll")}`
      }
    >
      <div
        ref={closePopup}
        className={
          shareVisibility
            ? "container-signup-form-users  container-signup-form-users-show  "
            : "container-signup-form-users  container-signup-form-users-hide "
        }
      >
        <span
          style={{ marginTop: "0" }}
          ref={closeButton}
          className=" btn-danger-product"
        >
          &times;
        </span>
        <div
          style={{
            textAlign: "center !important",
            margin: "2rem 0",
          }}
        >
          <p style={{ fontWeight: "bold" }}>
            Please share this post on your network
          </p>
          <div
            style={{
              margin: "0 auto !important",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              //               width: "100%",
            }}
          >
            <Share />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShareSingleParentComment;
