import React from "react";
import { Link } from "react-router-dom";

const Subscribe = () => {
  const handleClick = (event) => {
    event.preventDefault();
  };
  return (
    <div className="footer-product-links">
      <h4>Our Products</h4>
      <p>
        <Link
          to=""
          title="not available yet , add your email"
          onClick={handleClick}
        >
          Explore and find relevant products
        </Link>
      </p>
      <p>
        <Link
          to=""
          title="not available yet , add your email"
          onClick={handleClick}
        >
          Sign up a course to learn how to code
        </Link>
      </p>
      <p>
        <Link to="" onClick={handleClick}>
          Leave a review for other HackersDev
        </Link>
      </p>
      <p>
        <Link to="" onClick={handleClick}>
          Become a contributors
        </Link>
      </p>
      <p>
        <Link to="/tutorials">Access our tech tutorials</Link>
      </p>
      <p>
        <Link to="">Find a mentor</Link>
      </p>
      <p>
        <Link to="">Access our interview questions resources</Link>
      </p>
      <p>
        <Link to="/blogs">Access our free latest free blogs</Link>
      </p>
    </div>
  );
};

export default Subscribe;
