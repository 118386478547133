import React from "react";
import facebookLogo from "../assets/sectionOfThePage.jpg";
import Title from "../components/Title";

const Goals = ({ title }) => {
  return (
    <section className="section">
      <Title title={title} specificTitle />
      <div className="goal-article">
        <div>
          <h2>The power of breaking down the webpage in different section</h2>
          <p>
            One of the things that we find very hard for beginners is the
            ability transform any PSD file into html and css from scratch, which
            is the core concept of web development specifically for someone who
            is planning to be a front end developer. Most newbies developer only
            have it as task at their first job and it is very crucial to be able
            to look any webpage and design from scratch. Again the project
            section will help you to implement it easily. in addition, when most
            developers just get started, they dont know how to use any software
            tools like adobe, illustrators. this is why we believe the ablity to
            design in the piece of paper help you to really understand things
            before moving to the software aspect
          </p>
        </div>
        <img src={facebookLogo} alt="example of design by paper" />
      </div>
    </section>
  );
};

export default Goals;
