import React, { useContext } from "react";
import Title from "../components/Title";
import { Link } from "react-router-dom";
import Tutorial from "./Tutorial";
import { blogContext } from "../App";
import Loading from "../components/Loading";

const Tutorials = (props) => {
  const { featuredTutorials, loading } = useContext(blogContext);
  const featuredTutorial = featuredTutorials();
  const { title, showLink } = props;
  if (loading) {
    return <Loading title=" tutorials are currently loading ..." />;
  }
  return (
    <section className="section">
      <Title title={title} specificTitle />
      <div className="section-center">
        {featuredTutorial.map((tutorial) => {
          return <Tutorial key={tutorial.id} {...tutorial} />;
        })}
      </div>
      {showLink && (
        <Link to="/tutorials/" className="center-btn btn-primary">
          {" "}
          explore tutorials
        </Link>
      )}
    </section>
  );
};

export default Tutorials;
