import React from "react";
import { Link } from "react-router-dom";

const Tutorial = (props) => {
  const { title, alt, desc, category, date, image, id, slug, stacks } = props;
  // const { title, alt, desc, category, date, image, id, slug, stacks } = blog;

  const allStack = () => {
    const products = stacks.map((stack) => {
      const { typeOfProduct, id } = stack;
      return <span key={id}>{typeOfProduct}</span>;
    });
    return products;
  };

  return (
    <Link className="article-link tutorial" key={id} to={`/tutorials/${slug}`}>
      <article className="tutorial-article">
        <div>
          <div className="container-image tutorial-img">
            <img src={`${image.url}`} alt={alt} />
          </div>
          <div className="tutorial-card">
            <h4>{title}</h4>
            <p className="description">
              {desc} <span>...read all</span>
            </p>
            <div className="tutorial-footer">{allStack()}</div>
            <div className="tutorial-instructor-date">
              <div className="tutorial-instructor-date_instructor">
                <img src="https://res.cloudinary.com/ground-service-international/image/upload/v1646568602/batisseur_d4da04087c.jpg" />
              </div>
              <span>{date}</span>
            </div>
          </div>
        </div>
      </article>
    </Link>
  );
};

Tutorial.propTypes = {};
export default Tutorial;
