import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import { FcCheckmark } from "react-icons/fc";
import { CgDollar } from "react-icons/cg";
export default [
  {
    id: 1,
    title: "basic",
    sign: "",
    price: "Free",
    published_at: "2021-06-10T07:53:50.114Z",
    created_at: "2021-06-10T07:52:50.656Z",
    updated_at: "2021-06-10T11:19:58.883Z",
    info: [
      {
        id: 1,
        title: "free courses and tutorials",
        icon: <FcCheckmark className="price-icon" />,
      },
      {
        id: 3,
        title: "programming courses",
        icon: <AiOutlineClose className="price-icon" />,
      },
      {
        id: 4,
        title: "facebook group access",
        icon: <AiOutlineClose className="price-icon" />,
      },
      {
        id: 5,
        title: "career bundles",
        icon: <AiOutlineClose className="price-icon" />,
      },
      {
        id: 2,
        title: "make money online courses",
        icon: <AiOutlineClose className="price-icon" />,
      },
    ],
  },
  {
    id: 2,
    title: "monthly",
    sign: <CgDollar />,
    price: "$25",
    published_at: "2021-06-10T07:58:05.942Z",
    created_at: "2021-06-10T07:57:42.624Z",
    updated_at: "2021-06-10T07:58:05.989Z",
    info: [
      {
        id: 6,
        title: "free courses  and tutorials",
        icon: <FcCheckmark className="price-icon" />,
      },
      {
        id: 8,
        title: "programming courses",
        icon: <FcCheckmark className="price-icon" />,
      },
      {
        id: 9,
        title: "facebook group access",
        icon: <FcCheckmark className="price-icon" />,
      },
      {
        id: 10,
        title: "all career bundles ",
        icon: <AiOutlineClose className="price-icon" />,
      },
      {
        id: 7,
        title: "make money online courses",
        icon: <AiOutlineClose className="price-icon" />,
      },
    ],
  },
  {
    id: 3,
    title: "yearly ",
    sign: <CgDollar />,
    price: "$400",
    published_at: "2021-06-10T08:01:01.060Z",
    created_at: "2021-06-10T08:00:57.871Z",
    updated_at: "2021-06-10T08:01:01.097Z",
    info: [
      {
        id: 11,
        title: "all courses plus",
        icon: <FcCheckmark className="price-icon" />,
      },
      {
        id: 15,
        title: "programming courses",
        icon: <FcCheckmark className="price-icon" />,
      },
      {
        id: 12,
        title: "facebook group access",
        icon: <FcCheckmark className="price-icon" />,
      },
      {
        id: 13,
        title: "all career bundles",
        icon: <FcCheckmark className="price-icon" />,
      },
      {
        id: 14,
        title: "make money online courses",
        icon: <FcCheckmark className="price-icon" />,
      },
    ],
  },
];
