import React, { useState, useRef, useEffect, useContext } from "react";

import axios from "axios";

import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { blogContext } from "../App";

function AddChildComment(props) {
  let {
    visible,
    handleReplyComment,
    slug,
    title,
    uniqueId,
    parentId,
    category,
  } = props;

  const initialValues = {
    comment: "",
  };
  const [data, setData] = useState(initialValues);

  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const { user } = useAuth0();
  const history = useHistory();
  const { setComment } = useContext(blogContext);

  useEffect(() => {
    if (visible) {
      closeButton.current.addEventListener("click", handleReplyComment);
    }
    return () => {
      closeButton.current.removeEventListener("click", handleReplyComment);
    };
  }, [visible]);
  useEffect(() => {
    if (visible) {
      cancelButton.current.addEventListener("click", handleReplyComment);
    }
    return () => {
      cancelButton.current.removeEventListener("click", handleReplyComment);
    };
  }, [visible]);

  // start state for data to post

  // end state for data to post
  // start reference of all the input
  // const inputName = useRef();

  const globalContainer = useRef();
  const closeButton = useRef();
  const cancelButton = useRef();
  const closePopup = useRef();
  const removeMessageUser = useRef();
  const handleRemoveMessage = () => {
    setTimeout(() => {
      if (removeMessageUser.current !== null) {
        removeMessageUser.current.style.display = "none";
      } else {
        return;
      }
    }, 500);
  };

  //end reference of all the input
  // start of position of the input within the
  const close = () => {
    setTimeout(() => {
      if (visible && closePopup.current !== null) {
        handleReplyComment();
      }
    }, 500);
  };

  const handleComment = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log(data);
    }
  }, [formErrors]);
  const validate = (values) => {
    const errors = {};
    if (!values.comment) {
      errors.comment = "Your comment is empty";
    }
    return errors;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(data));
    let result = validate(data);

    setIsSubmit(true);

    if (Object.keys(result).length === 0) {
      axios
        .post("http://localhost:1337/Comments", {
          ...data,
          title: title,
          email: user.email,
          uniqueId: uniqueId,
          username: user.given_name ? user.given_name : user.nickname,
          image: user.picture,
          parentId: parentId,
          category: category,
        })
        .then(() => {
          setData({
            comment: "",
          });
          handleRemoveMessage();
          close();
          history.push({
            pathname: `/${category}/${slug}`,
          });
          axios
            .get("http://localhost:1337/comments")
            .then((response) => {
              return response.data;
            })
            .then((data) => {
              setComment(data);
            })
            .catch(function (error) {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log("An error occurred:", error.response);
        });
    } else {
      return;
    }
  };

  const body = document.body;

  return (
    <div
      ref={globalContainer}
      className={
        visible
          ? `global-container-signup-form-users global-container-signup-form-users-show     ${(body.style.overflow =
              "hidden")}`
          : `global-container-signup-form-users global-container-signup-form-users-hide  ${(body.style.overflow =
              "scroll")}`
      }
    >
      <div
        ref={closePopup}
        className={
          visible
            ? "container-signup-form-users  container-signup-form-users-show  "
            : "container-signup-form-users  container-signup-form-users-hide "
        }
      >
        <span
          style={{ marginTop: "0" }}
          ref={closeButton}
          className=" btn-danger-product"
        >
          &times;
        </span>

        <form
          style={{ margin: "2rem auto", width: "80%" }}
          className="global-container-comments-user "
          onSubmit={handleSubmit}
        >
          {Object.keys(formErrors).length === 0 && isSubmit && (
            <div ref={removeMessageUser} className="successReview">
              😊 Thanks for your replies 😊
            </div>
          )}
          <textarea
            style={{ marginTop: "2rem " }}
            placeholder="Write your reply here ..."
            value={data.comment}
            onChange={handleComment}
            name="comment"
          ></textarea>
          <p className="errorReview">{formErrors.comment}</p>

          <button type="submit" className="btn-primary">
            reply
          </button>
          <button
            type="button"
            ref={cancelButton}
            style={{ marginLeft: "2%" }}
            className="btn-primary"
          >
            cancel
          </button>
        </form>
      </div>
    </div>
  );
}
export default AddChildComment;
