import React from "react";

const ContactUs = () => {
  return (
    <div>
      <h4>Contact us</h4>
      <h6 className="office-address">Hackersdev's Headquarters:</h6>
      <address>13809 Jefferson Park, suite 3404 </address>
      <h4>support team:</h4>
      <h6 className="email-address">contact@hackersdev.com</h6>
    </div>
  );
};

export default ContactUs;
