import React, { useContext } from "react";
import Title from "../components/Title";
import Blog from "../components/Blog";
import Layout from "../components/Layout";
import Hero from "../components/Hero";
import Banner from "../components/Banner";
import Email from "../components/Email";
import Loading from "../components/Loading";

import { blogContext } from "../App";
import { Link } from "react-router-dom";
import DescriptionAuthor from "../components/DescriptionAuthor";
import StyledHero from "../components/StyledHero";
import Seo from "../components/Seo";

const Blogs = (props) => {
  const { allBlogs, loadingBlog } = useContext(blogContext);

  const { showLink } = props;
  return (
    <>
      <Seo />
      <Layout>
        <StyledHero img="https://res.cloudinary.com/ground-service-international/image/upload/v1627080722/medium_work_abroad_15f38a889a.jpg">
          <Banner
            title="we love blogging"
            subtitle="You will read stories about successful software engineers and cyberSecurity Experts "
          ></Banner>
        </StyledHero>

        <section className="section">
          <Title title=" We love blogging about tech" specificTitle />
          {/* <Filter /> */}
          <div className=" global-container-position-blog  ">
            {loadingBlog && (
              <Loading title="all blogs are currently loading ..." />
            )}

            <div className="section-center">
              {allBlogs.map((blog) => {
                return <Blog key={blog.id} {...blog} />;
              })}
            </div>
            {showLink && (
              <Link to="/blog" className=" center-btn btn-primary">
                explore blogs
              </Link>
            )}
            <div className="section-position-email">
              <Email />
              <DescriptionAuthor />
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default Blogs;
