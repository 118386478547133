import React from "react";

export default function Objectif({ image, alt, title }) {
  return (
    <article className="tutorial-article">
      <div className="container-career container-objectif">
        <div>
          <img src={image} loading="lazy" alt={alt} />
        </div>
        <span>{title}</span>
      </div>
    </article>
  );
}
