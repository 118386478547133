import uuid from "react-uuid";

const descriptions = [
  {
    id: uuid(),
    description:
      " Increase reach to customers and clients on smaller devices (tablets & smartphones)",
  },
  {
    id: uuid(),
    description:
      " A consistent experience that can increase lead generation, sales and conversions",
  },
  {
    id: uuid(),
    description: " Analytics, tracking, and reporting can all be in one place",
  },

  {
    id: uuid(),
    description:
      "even 44% of Fortune 500 companies are not mobile-ready at this time. this is why as developer, you should  be proficient writting an efficient media query targeting all screen size ",
  },
  {
    id: uuid(),
    description:
      "you are lucky to find this website, you will learn everything to become job ready whether by blogs or videos",
  },
];

export default descriptions;
