import React, { useEffect, useRef, useMemo } from "react";
import Share from "../components/Share";
function DeleteSingleParentComment(props) {
  const {
    deleteVisibility,
    handlePoPupDeleteComment,

    handleDeleteComment,
    isPostDeleted,
    id,
    comments,
  } = props;

  const removeMessageUser = useRef();

  const memoChild = useMemo(() => {
    return (
      <>
        <p
          style={{ textAlign: "center" }}
          ref={removeMessageUser}
          className="successReview"
        >
          Post successfully deleted!!!❌
        </p>
      </>
    );
  }, [comments]);
  const globalContainer = useRef();
  const closeButton = useRef();

  const closePopup = useRef();
  useEffect(() => {
    if (deleteVisibility) {
      closeButton.current.addEventListener("click", handlePoPupDeleteComment);
    }
    return () => {
      closeButton.current.removeEventListener(
        "click",
        handlePoPupDeleteComment
      );
    };
  }, [deleteVisibility]);
  const body = document.body;
  return (
    <div
      ref={globalContainer}
      className={
        deleteVisibility
          ? `global-container-signup-form-users global-container-signup-form-users-show     ${(body.style.overflow =
              "hidden")}`
          : `global-container-signup-form-users global-container-signup-form-users-hide  ${(body.style.overflow =
              "scroll")}`
      }
    >
      <div
        ref={closePopup}
        className={
          deleteVisibility
            ? "container-signup-form-users  container-signup-form-users-show  "
            : "container-signup-form-users  container-signup-form-users-hide "
        }
      >
        <span
          style={{ marginTop: "0" }}
          ref={closeButton}
          className=" btn-danger-product"
        >
          &times;
        </span>
        <div
          style={{
            textAlign: "center !important",
            margin: "2rem 0",
          }}
        >
          {isPostDeleted && memoChild}

          <p style={{ fontWeight: "bold" }}>
            Do you want to delete this comment 😥 ?
          </p>
          <div
            style={{
              margin: "0 auto !important",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div>
              <span
                onClick={() => handleDeleteComment(id)}
                className="btn-primary"
              >
                Yes
              </span>
              <span onClick={handlePoPupDeleteComment} className="btn-primary">
                No
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteSingleParentComment;
