import React from "react";

const Title = ({ title, specificTitle, separateColor }) => {
  return (
    <div id="title" className="section-title">
      {specificTitle && separateColor ? (
        <h2 style={{ margin: "1rem 0 1.5rem,", color: "black" }}>
          {title || "default title"}
        </h2>
      ) : (
        <h1 id="title2" style={{ margin: "1rem 0 1.5rem" }}>
          {title || "default title"}
        </h1>
      )}

      {/* <div className='underline'></div> */}
    </div>
  );
};

export default Title;
