import React from "react";
import { AiFillCaretDown } from "react-icons/ai";
import { useAuth0 } from "@auth0/auth0-react";

function BtnSignDesktop({ handleVisible }) {
  const { isAuthenticated, loginWithRedirect, user } = useAuth0();

  const handleLogin = () => {
    // Store the current path in session storage
    sessionStorage.setItem("redirectPath", window.location.pathname);

    // Redirect to the login page
    loginWithRedirect();
  };

  return (
    <div className="btn-sign-desktop">
      {isAuthenticated ? (
        <div className="container-logo-image-name-icon" onClick={handleVisible}>
          <div className="container-logo-image">
            <img
              src={user.picture}
              alt={user.given_name || user.nickname || user.name}
            />
          </div>
          <span>{user.given_name || user.nickname || user.name}</span>

          <AiFillCaretDown className="container-logo-image-name-icon_icon" />
        </div>
      ) : (
        <div className="container-login-signup">
          <button
            onClick={handleLogin}
            type="button"
            className="nav-icon-login-desktop"
          >
            Login
          </button>
        </div>
      )}
    </div>
  );
}

export default BtnSignDesktop;
