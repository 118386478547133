import React from "react";
import { useHistory } from "react-router-dom";

const Authentification = ({ visible, logout, user, isAuthenticated }) => {
  const history = useHistory();

  const handleLogout = () => {
    const currentUrl = window.location.pathname + window.location.search; // Capture current path and query params
    localStorage.setItem("lastVisitedUrl", currentUrl);
    logout();
    history.push(currentUrl); // Redirect to the current page after logout
    window.location.reload(); // Reload the page to reflect the logout state
  };

  return (
    <div
      className={
        visible
          ? "visible_container container_information_user_products"
          : "unvisible_container container_information_user_products"
      }
    >
      <span title="close button" className="close-btn">
        &times;
      </span>
      <div className="container_information_user_products_row1">
        <span>Welcome,</span>
        {isAuthenticated && <span> {user.given_name || user.nickname}</span>}
        <div className="nav-header">
          <a href="/" className="container-logo">
            <div className="container-logo-symbol">
              <span>HD</span>
            </div>
            <div className="container-logo-title">
              <span>HackersDev</span>
            </div>
          </a>
        </div>
      </div>
      <p className="container_information_user_products_row2">
        Social Sharing Platform for SoftwareEngineers and <br></br>
        CyberSecurity experts
      </p>
      <button className="btn-primary" onClick={handleLogout}>
        logout
      </button>
    </div>
  );
};

export default Authentification;
