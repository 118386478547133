import React from "react";
import { Link } from "react-router-dom";

const ImportantLinks = () => {
  const handleClick = (event) => {
    event.preventDefault();
  };
  return (
    <div>
      <h4>important links</h4>
      <ul>
        <li>
          <Link onClick={handleClick} to="">
            {" "}
            Terms and Conditions
          </Link>
        </li>
        <li>
          <Link onClick={handleClick} to="">
            {" "}
            Disclamer
          </Link>
        </li>
        <li>
          <Link onClick={handleClick} to="">
            {" "}
            privacy
          </Link>
        </li>
        <li>
          <Link onClick={handleClick} to="">
            {" "}
            cooking policy
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default ImportantLinks;
