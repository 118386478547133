import React, { useContext } from "react";
import Title from "../components/Title";
import Layout from "../components/Layout";
import Banner from "../components/Banner";
import Hero from "../components/Hero";
import { Link, withRouter } from "react-router-dom";
import TutorialCategories from "../components/TutorialCategories";
import { blogContext } from "../App";
import Loading from "../components/Loading";
import DescriptionAuthor from "../components/DescriptionAuthor";
import Email from "../components/Email";
import Share from "../components/Share";
import uuid from "react-uuid";
import StyledHero from "../components/StyledHero";
import Seo from "../components/Seo";

const SingleCategoryTutorials = (props) => {
  const { tutorials, setLoadingSingleTutorial, loadingSingleTutorial } =
    useContext(blogContext);
  const url = props.match.params.slug;
  const { getUniqueTutorials, RemoveCurrentTutorials } =
    useContext(blogContext);
  const removeCurrentTutorial = RemoveCurrentTutorials(url);
  const singleTutorial = getUniqueTutorials(url);

  if (!singleTutorial) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "100vh",
        }}
        className="error"
      >
        <h3>Category tutorial is loading...</h3>

        <p>Please Wait ...</p>
        <Loading />
      </div>
    );
  }

  const { singleCategoryTutorial, SeoData } = singleTutorial;

  if (singleCategoryTutorial) {
    setLoadingSingleTutorial(true);
  }

  const findIndexClick = (id) => {
    let data = singleCategoryTutorial.find((item) => item.id === id);

    let result = data.title;

    result = result.trim().replace(/\s+/g, "-");
    return result;
  };
  const CutDownWord = (title) => {
    let newWord = title.substring(0, 150);
    return newWord;
  };

  const handleClick = (id) => {
    const slug = findIndexClick(id);
    const data = {
      slug: slug,
      applied: true,
      datas: singleTutorial.tutorial,
      allData: removeCurrentTutorial,
    };
    localStorage.setItem("tutorialData", JSON.stringify(data));
    return slug;
  };

  return (
    <>
      <Seo seoData={SeoData} />
      <Layout>
        <StyledHero img="https://res.cloudinary.com/ground-service-international/image/upload/v1716674553/ratemymajor_computer_science.jpg">
          <Banner
            title="welcome to the tech tutorials department"
            subtitle="we work hard to provide you the best well-explained tech-tutorials ever"
          ></Banner>
        </StyledHero>
        <section className="section">
          <Title
            title="Learn and master real world best tutorials  from professionals. "
            specificTitle
          />

          <div className=" global-container-position-blog  ">
            <div className="section-center">
              {singleCategoryTutorial.map((tutorial) => {
                const { title, description, stacks, image, date, id } =
                  tutorial;

                return (
                  /*     <Link
                  onClick={() => findIndexClick(id)}
                  className="article-link tutorial"
                  key={uuid()}
                  to={{
                    pathname: `/alltutorials/${findIndexClick(id)}`,

                    key: uuid(),
                    state: {
                      slug: findIndexClick(id),
                      applied: true,
                      datas: singleTutorial.tutorial,
                      allData: removeCurrentTutorial,
                    },
                  }}
                > */
                  <Link
                    onClick={() => handleClick(id)}
                    className="article-link tutorial"
                    key={uuid()}
                    to={`/alltutorials/${handleClick(id)}`}
                  >
                    <article className="tutorial-article tutorial-article-background-image ">
                      <div className="">
                        <div className="tutorial-card">
                          <h4>{title.replaceAll("-", " ")}</h4>
                          <p className="description">
                            {CutDownWord(description)} <span>...read all</span>
                          </p>
                          {/* <div className="tutorial-footer">{allStack()}</div> */}
                          <div className="tutorial-instructor-date">
                            <div className="tutorial-instructor-date_instructor">
                              <img src="https://res.cloudinary.com/ground-service-international/image/upload/v1646568602/batisseur_d4da04087c.jpg" />
                            </div>
                            <span>{date}</span>
                          </div>
                        </div>
                      </div>
                    </article>
                  </Link>
                );
              })}
            </div>
            <div className="section-position-email">
              <Email />
              <DescriptionAuthor />
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default withRouter(SingleCategoryTutorials);
