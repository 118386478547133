import React, { useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import axios from "axios";

import Home from "./pages/Home";
import About from "./pages/About";
import Tutorial from "./pages/Tutorial";
import blogs from "./pages/blogs";

import SingleCategoryTutorials from "./pages/SingleCategoryTutorials";
import SingleBlog from "./pages/SingleBlog";

import Team from "./pages/Team";
import Error from "./pages/Error";
import "./App.css";
import SingleTutorials from "./pages/SingleTutorial";

import { useAuth0 } from "@auth0/auth0-react";

export const blogContext = React.createContext();

function App() {
  const { user } = useAuth0();
  /** loading **/
  // const [loadingWorkers, setLoadingWorkers] = useState(true);
  // const [loadingSkills, setLoadingSkills] = useState(true);
  const [loadingBlog, setLoadingBlog] = useState([]);
  const [loadingTutorial, setLoadingTutorial] = useState(true);

  const [loadingTeam, setLoadingTeam] = useState(true);
  const [loadingSingleBlog, setLoadingSingleBlog] = useState(true);
  const [loadingSingleTutorial, setLoadingSingleTutorial] = useState(true);
  const [loadingEmail, setLoadingEmail] = useState(true);

  const [loadingSingleTutorialCategory, setLoadingSingleTutorialCategory] =
    useState(true);

  const [loading, setLoading] = useState(true);

  const [allBlogs, setAllBlog] = useState([]);
  const [tutorials, setTutorials] = useState([]);

  const [careers, setCareers] = useState([]);

  const [objectifs, setObjectifs] = useState([]);

  const [emails, setEmail] = useState([]);

  const [likes, setLike] = useState([]);
  const [comments, setComment] = useState([]);

  const getAllBlog = async () => {
    const response = await fetch("http://localhost:1337/Blogs");
    const allBlog = await response.json();
    setAllBlog(allBlog);
    setLoadingBlog(false);
  };

  const getAllTutorial = async () => {
    const response = await fetch("http://localhost:1337/tutorials");
    const AllTutorials = await response.json();
    setTutorials(AllTutorials);
    setLoadingTutorial(false);
  };

  const getAllEmail = async () => {
    const response = await fetch("http://localhost:1337/Emails");
    const AllEmail = await response.json();
    setEmail(AllEmail);
    setLoadingEmail(false);
  };
  const getAllComments = async () => {
    const response = await fetch("http://localhost:1337/Comments", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(),
    });
    const AllComments = await response.json();
    setComment(AllComments);
    setLoading(false);
  };
  const getAllLikes = async () => {
    const response = await fetch("http://localhost:1337/Likes", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(),
    });
    const AllLikes = await response.json();
    setLike(AllLikes);
    setLoading(false);
  };

  /* start of the like button */
  const postLike = (title, category) => {
    const result = likes.find(
      (comment) =>
        comment.email === user.email &&
        comment.title === title &&
        comment.category === category
    );

    if (likes.length > 0 && result !== undefined) {
      const { PostLike, id } = result;

      axios
        .put(`http://localhost:1337/Likes/${id}`, {
          PostLike: (PostLike === 0 && 1) || (PostLike === 1 && 0),
        })
        .then(() => {
          axios
            .get("http://localhost:1337/Likes")
            .then((response) => {
              return response.data;
            })
            .then((data) => {
              setLike(data);
            });
        });
    } else {
      axios
        .post("http://localhost:1337/Likes", {
          username:
            user.given_name || user.username || user.nickname || user.name,
          email: user.email,
          title: title,
          image: user.picture,
          PostLike: 1,
          category: category,
        })
        .then(() => {
          axios
            .get("http://localhost:1337/Likes")
            .then((response) => {
              return response.data;
            })
            .then((data) => {
              setLike(data);
            });
        });
    }
  };
  let totalLike = (array, title, category) => {
    let totalCountLike = array
      .filter((like) => like.title === title && like.category === category)
      .reduce((accumulator, currentValue) => {
        return accumulator + currentValue.PostLike;
      }, 0);
    return totalCountLike;
  };
  let allCommentCounts = (array, title, category) => {
    let result = array.filter((comment) => {
      return (
        comment.category === category &&
        comment.comment !== null &&
        // comment.type === type &&
        comment.title === title
      );
    });
    return parseInt(result.length);
  };

  useEffect(() => {
    getAllLikes();
  }, []);
  useEffect(() => {
    getAllComments();
  }, []);

  useEffect(() => {
    getAllTutorial();
  }, []);

  useEffect(() => {
    getAllBlog();
  }, []);

  useEffect(() => {
    getAllEmail();
  }, []);

  //get unique blog
  const getUniqueBlog = (slug) => {
    const uniqueBlog = allBlogs.find((blog) => blog.slug === slug);
    return uniqueBlog;
  };

  const RemoveCurrentBlog = (slug) => {
    const filterBlog = allBlogs.filter((blog) => blog.slug !== slug);
    return filterBlog;
  };

  const featuredBlog = () => {
    const feature = allBlogs.filter((blog) => blog.featured === true);
    return feature;
  };

  /**  start of the tutorials logic */
  const getUniqueTutorials = (slug) => {
    const uniqueTutorial = tutorials.find((tutorial) => tutorial.slug === slug);
    return uniqueTutorial;
  };

  const RemoveCurrentTutorials = (slug) => {
    const filterTutorial = tutorials.filter(
      (tutorial) => tutorial.slug !== slug
    );
    return filterTutorial;
  };

  const featuredTutorials = () => {
    const feature = tutorials.filter((tutorial) => tutorial.featured === true);
    return feature;
  };
  /* end of the tutorial */

  /* start of comment section */
  const editComment = (title, id) => {
    const filterComment = comments.filter(
      (comment) => comment.title === title && comment.id === id
    );
    return filterComment;
  };
  const deleteComment = (title, id) => {
    const filterComment = comments.filter(
      (comment) => comment.title === title && comment.id !== id
    );
    return filterComment;
  };
  const replyComment = (title, id) => {
    const filterComment = comments.filter(
      (comment) =>
        comment.title === title &&
        comment.parentId === id &&
        comment.comment !== null
    );
    return filterComment;
  };
  /**  end fo the comment section */

  /**  function to update the current time */
  let updateTime = (time) => {
    let month;
    switch (parseInt(time)) {
      case 1:
        month = "January";
        break;
      case 2:
        month = "February";
        break;
      case 3:
        month = "March";
        break;
      case 4:
        month = "April";
        break;
      case 5:
        month = "May";
        break;

      case 6:
        month = "June";
        break;
      case 7:
        month = "July";
        break;
      case 8:
        month = "August";
        break;
      case 9:
        month = "SEP";
        break;
      case 10:
        month = "OCT";
        break;
      case 11:
        month = "NOV";
        break;
      case 12:
        month = "DEC";
        break;
    }
    return month;
  };

  /**  function to update the curent time */

  /* function to create a better based on hours, days, */
  const calculateRelativeTime = (timestamp) => {
    const now = new Date();

    const timeDifference = now - timestamp;
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30.44); // Approximate number of days in a month
    const years = Math.floor(months / 12);

    if (years >= 1) {
      return `${years} year${years > 1 ? "s" : ""} ago`;
    } else if (months >= 1) {
      return `${months} month${months > 1 ? "s" : ""} ago`;
    } else if (days >= 1) {
      return `${days} day${days > 1 ? "s" : ""} ago`;
    } else if (hours >= 1) {
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (minutes >= 1) {
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else {
      return `${seconds} second${seconds > 1 ? "s" : ""} ago`;
    }
  };

  /**  function to create a better based on hours , day */
  return (
    <>
      <blogContext.Provider
        value={{
          loadingBlog,
          loadingTutorial,
          loadingEmail,
          loadingTeam,
          allBlogs,
          loadingSingleTutorial,
          loadingSingleTutorialCategory,
          setLoadingSingleTutorial,
          setLoadingSingleTutorialCategory,
          setLoadingSingleBlog,
          loadingSingleBlog,
          featuredBlog,
          getUniqueBlog,
          RemoveCurrentBlog,
          loading,
          setLoading,
          tutorials,
          getUniqueTutorials,
          RemoveCurrentTutorials,
          featuredTutorials,
          careers,
          objectifs,
          emails,
          comments,
          setComment,
          editComment,
          replyComment,
          deleteComment,
          likes,
          setLike,
          postLike,
          totalLike,
          allCommentCounts,
          updateTime,
          calculateRelativeTime,
        }}
      >
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about/" component={About} />
          <Route exact path="/tutorials/" component={Tutorial} />
          <Route exact path="/blogs/" component={blogs} />
          <Route
            exact
            path="/tutorials/:slug"
            component={SingleCategoryTutorials}
          />
          <Route exact path="/alltutorials/:slug" component={SingleTutorials} />
          <Route exact path="/blogs/:slug/" component={SingleBlog} />
          <Route exact path="/team/" component={Team} />
          <Route component={Error} />
        </Switch>
      </blogContext.Provider>
    </>
  );
}

export default App;
