import React, { useContext, useState, useEffect } from "react";
import GrandChildComment from "./GrandChildComment";
import AddGrandChildComment from "./AddGrandChildComment";
import EditGrandChildComment from "./EditGrandChildComment";
import { blogContext } from "../App";
import { useAuth0 } from "@auth0/auth0-react";
import { Parser } from "simple-text-parser";
import urlRegex from "url-regex";
import axios from "axios";
import DeleteChildComment from "./DeleteChildComment";

function ChildComment({
  username,
  id,
  image,
  comment,
  created_at,
  title,
  email,
  type,
  slug,
  category,
  handleShareComment,
}) {
  let parser = new Parser();
  const re = urlRegex();
  parser = parser.addRule(re, (url) => {
    return `<a href= ${url} target="_blank" rel="noopener noreferrer">${url}</a>`;
  });
  const { user, isAuthenticated, loginWithRedirect } = useAuth0();
  let { comments, setComment, updateTime, calculateRelativeTime } =
    useContext(blogContext);

  const currentUserEmail = email;

  const canEdit = isAuthenticated && currentUserEmail === user.email;

  const canReply = username;
  let time = new Date(created_at).toLocaleDateString("en-US").split("/");
  let currentTime = `${updateTime(time[0])} ${time[1]}, ${time[2]}`;

  const [visible, setVisible] = useState(false);
  const [editChildComment, setEditChildComment] = useState(false);
  const [deleteVisibility, setDeleteVisibility] = useState(false);
  const [isPostDeleted, isSetPostDeleted] = useState(false);
  let data = comments.filter((comment) => {
    return (
      comment.title === title &&
      comment.parentId === id &&
      comment.comment === null &&
      comment.category === category
    );
  });

  comments = comments.filter(
    (comment) =>
      comment.title === title &&
      comment.parentId === id &&
      comment.comment !== null &&
      comment.category === category
  );
  const handleChildReplyComment = () => {
    setVisible((prev) => {
      return !prev;
    });
  };
  const handleChildEditComment = () => {
    setEditChildComment((prev) => {
      return !prev;
    });
  };
  const handlePostDeleted = () => {
    isSetPostDeleted((prev) => {
      return !prev;
    });
  };
  const handleChildDeleteComment = async (id) => {
    axios.delete(`http://localhost:1337/comments/${id}`).then(() => {
      axios
        .get("http://localhost:1337/comments")
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          setComment(data);
          handlePostDeleted();
        })
        .catch(function (error) {
          console.log(error);
        });
    });
    await handlePostDeleted();
  };

  const handlePoPupDeleteComment = () => {
    setDeleteVisibility((prev) => {
      return !prev;
    });
  };

  const handleLogin = () => {
    // Store the current path in session storage
    sessionStorage.setItem("redirectPath", window.location.pathname);

    // Redirect to the login page
    loginWithRedirect();
  };

  const [relativeTime, setRelativeTime] = useState("");

  useEffect(() => {
    if (created_at) {
      // Parse the provided created_at timestamp in UTC format
      const timestamp = new Date(created_at);

      // Calculate the relative time based on your local timezone
      setRelativeTime(calculateRelativeTime(timestamp));

      // Update the relative time every 10 seconds
      const intervalId = setInterval(() => {
        setRelativeTime(calculateRelativeTime(timestamp));
      }, 10000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [created_at]);

  const handleChildLikeComment = (parentId) => {
    let singleLike = data.find(
      (comment) =>
        comment.parentId === parentId &&
        comment.email === user.email &&
        comment.comment === null &&
        comment.category === category
    );
    if (singleLike === undefined) {
      axios
        .post("http://localhost:1337/Comments", {
          username: user.given_name || user.username || username.name,
          email: user.email,
          parentId: parentId,
          title: title,
          comment: null,
          image: user.picture,
          like: 1,
          category: category,
        })
        .then(() => {
          axios
            .get("http://localhost:1337/Comments")
            .then((response) => {
              return response.data;
            })
            .then((data) => {
              setComment(data);
            });
        });
    } else {
      const { id, like } = data.find(
        (comment) =>
          comment.parentId === parentId &&
          comment.comment === null &&
          comment.email === user.email &&
          comment.category === category
      );
      axios
        .put(`http://localhost:1337/Comments/${id}`, {
          like: (parseInt(like) === 0 && 1) || (parseInt(like) === 1 && 0),
        })
        .then(() => {
          axios
            .get("http://localhost:1337/Comments")
            .then((response) => {
              return response.data;
            })
            .then((data) => {
              setComment(data);
            })
            .catch((error) => {
              return error;
            });
        })
        .catch((error) => {
          return error;
        });
    }
  };
  // const handleShareComment = () => {
  //   alert("share please");
  // };
  const allLikes = data
    .filter((comment) => {
      return (
        comment.parentId === id &&
        comment.comment === null &&
        comment.category === category
      );
    })
    .reduce((acc, currentValue) => {
      return acc + currentValue.like;
    }, 0);
  return (
    <div>
      <div className="global-container-comments-user__container_profile_author_date">
        <div className="global-container-comments-user__container_profile">
          {" "}
          <img src={image} />
        </div>
        <div className="global-container-comments-user__container_author_date">
          <span>
            <b style={{ fontWeight: "bold" }}> @{username}</b>
          </span>
          {/* <span>{currentTime}</span> */}
          <span style={{ fontSize: "14px" }}>{relativeTime}</span>
        </div>
      </div>
      <div className="global-container-comments-user_comments">
        <p dangerouslySetInnerHTML={{ __html: parser.render(comment) }} />
      </div>
      <div className="global-container-comments-user_like_reply_edit_delete_share">
        {!isAuthenticated ? (
          <button onClick={handleLogin} className="btn-primary">
            👍 {allLikes}
          </button>
        ) : (
          <button
            onClick={() => handleChildLikeComment(id)}
            className="btn-primary"
          >
            👍 {allLikes}
          </button>
        )}

        {isAuthenticated && canReply !== undefined ? (
          <button onClick={handleChildReplyComment} className="btn-primary">
            reply
          </button>
        ) : (
          <button onClick={handleLogin} className="btn-primary">
            reply
          </button>
        )}

        {isAuthenticated ? (
          canEdit ? (
            <button onClick={handleChildEditComment} className="btn-primary">
              edit
            </button>
          ) : (
            ""
          )
        ) : (
          isAuthenticated && (
            <button onClick={handleLogin} className="btn-primary">
              edit
            </button>
          )
        )}

        {isAuthenticated ? (
          canEdit ? (
            <button onClick={handlePoPupDeleteComment} className="btn-primary">
              delete
            </button>
          ) : (
            ""
          )
        ) : (
          isAuthenticated && (
            <button onClick={handleLogin} className="btn-primary">
              delete
            </button>
          )
        )}

        {!isAuthenticated ? (
          <button onClick={handleLogin} className="btn-primary">
            {" "}
            share
          </button>
        ) : (
          <>
            <button onClick={handleShareComment} className="btn-primary">
              {" "}
              share
            </button>
          </>
        )}

        {/* <button className="btn-primary"> share</button> */}
      </div>
      <div className="global-container-comments-user__reply__reply">
        {comments.map((comment) => {
          const { id } = comment;
          return (
            <GrandChildComment
              key={id}
              {...comment}
              slug={slug}
              title={title}
              category={category}
              handleShareComment={handleShareComment}
              handleChildDeleteComment={() => handleChildDeleteComment(id)}
            />
          );
        })}
      </div>
      <AddGrandChildComment
        handleChildReplyComment={handleChildReplyComment}
        visible={visible}
        parentId={id}
        title={title}
        user={user}
        slug={slug}
        category={category}
        isAuthenticated={isAuthenticated}
      />
      <DeleteChildComment
        handlePoPupDeleteComment={handlePoPupDeleteComment}
        deleteVisibility={deleteVisibility}
        handleChildDeleteComment={handleChildDeleteComment}
        id={id}
        isPostDeleted={isPostDeleted}
        handlePostDeleted={handlePostDeleted}
        comments={comment}
        type="comment"
        handleShareComment={handleShareComment}
        // shareVisibility={shareVisibility}
      />
      <EditGrandChildComment
        parentId={id}
        title={title}
        slug={slug}
        comment={comment}
        handleChildEditComment={handleChildEditComment}
        editChildComment={editChildComment}
        category={category}
      />
    </div>
  );
}

export default ChildComment;
