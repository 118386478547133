import React from "react";
import Hero from "../components/Hero";
import Layout from "../components/Layout";
import Banner from "../components/Banner";
import { Link } from "react-router-dom";
import Seo from "../components/Seo";

const Error = () => {
  return (
    <>
      <Seo />
      <Layout>
        <Hero hero="defaultHero">
          <Banner
            title="Oops , something went wrong !!!"
            subtitle="you are currently on the error page, contact the admin"
          >
            <Link to="/" className="btn-primary">
              Go back Home
            </Link>
          </Banner>
        </Hero>
      </Layout>
    </>
  );
};
export default Error;
