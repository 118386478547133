import uuid from "react-uuid";
import backend from "../assets/career_path/backend_career_path.jpg";
import frontend from "../assets/career_path/front_end_career_path.jpg";
import fullStack from "../assets/career_path/fullStackDeveloper_career_path.jpg";
import php from "../assets/career_path/php_career_path.jpg";
import cyberSecurity from "../assets/career_path/cyberSecurity_career_path.jpg";
import entrepreneur from "../assets/career_path/entrepreneur_career_path.jpg";
import html from "../assets/career_path/html_email_career.jpg";
import wordpress from "../assets/career_path/WordPress_career_path.png";
const careers = [
  {
    id: uuid(),
    title: "HTML EMAIL",
    image: html,
    alt: "HTML EMAIL career path",
  },
  {
    id: uuid(),
    title: "Wordpress Developer",
    image: wordpress,
    alt: "Wordpress Developer",
  },
  {
    id: uuid(),
    title: "Frontend Engineer",
    image: frontend,
    alt: "frontend career path",
  },
  {
    id: uuid(),
    title: "Backend engineer",
    image: backend,
    alt: "backend career path",
  },
  {
    id: uuid(),
    title: "Full Stack Developer",
    image: fullStack,
    alt: "Full stack careeer path",
  },
  {
    id: uuid(),
    title: "PHP Developer",
    image: php,
    alt: "PHP  careeer path",
  },
  {
    id: uuid(),
    title: "Cyber Security Engineer",
    image: cyberSecurity,
    alt: "Cyber Security career path",
  },
  {
    id: uuid(),
    title: "Entrepreneur developer",
    image: entrepreneur,
    alt: "Entrepreneur career path",
  },
];
export default careers;
