import React from "react";
import ReactPlayer from "react-player/youtube";
function VideoPlayer({ videoUrl }) {
  return (
    <ReactPlayer
      url={videoUrl}
      controls
      className="React-player"
      unmuted="true"
    />
  );
}
export default VideoPlayer;
