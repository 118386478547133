import React from "react";
import Disclosure from "./Disclosure";

function BannerAffiliate({ date, author }) {
  return (
    <div className="global-disclosure-small-affiliate-section">
      <div className="global-disclosure-small-affiliate-section-inner-container">
        {/* <div>
          <span>Last updated on</span>
          <span>{date}</span>
          <span> {author} </span>
        </div> */}
        <Disclosure />
      </div>
    </div>
  );
}

export default BannerAffiliate;
