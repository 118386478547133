import React, { useState } from "react";
import Layout from "../components/Layout";
import Hero from "../components/Hero";
import Banner from "../components/Banner";
import { Link } from "react-router-dom";
import SociauxLinks from "../constant/SociauxLinks";
import batisseur from "../assets/img/batisseur.jpg";
import cameroonFlag from "../assets/img/cameroonFlag.png";
import usaFlag from "../assets/img/usaFlag.jpeg";
import aude from "../assets/img/aude.jpg";
import andy from "../assets/img/andy.jpg";
import brenda from "../assets/img/brenda.jpg";
import StyledHero from "../components/StyledHero";
import Title from "../components/Title";
import Seo from "../components/Seo";
import "../team.css";

const Team = () => {
  const [visible, setVisible] = useState(false);

  const handlevisible = () => {
    setVisible((prev) => {
      return !prev;
    });
  };
  return (
    <>
      <Seo />
      <Layout visible={visible} handlevisible={handlevisible}>
        <section className="section">
          <Title title="Hackersdev Team" />
          <div className="all-ceo-description-about">
            <div className="unique-employees-description-about">
              <div className="ceo-container-description-about">
                <h2 style={{ color: "#fff" }}>Gabriel Fono 🇨🇲</h2>
                <h4>
                  Software Engineer || Mathematicien || Civil Engineer
                  <br />
                  Digital online Flipper || Cyber Security Engineer{" "}
                </h4>
                <p>
                  Founder & CEO{" "}
                  <a
                    style={{ color: "#5aa3ed", fontWeigth: "bold" }}
                    href="https://hackersdev.com"
                  >
                    hackersdev.com
                  </a>
                </p>
              </div>
              <div className="ceo-picture-description-about">
                <div className="ceo-container-about">
                  <img src={batisseur} alt="Gabriel Andy Fono" />
                </div>
                <div className="container-sociaux-links-ceo-about">
                  <SociauxLinks />
                </div>
              </div>
            </div>

            <div className="ceo-background-about">
              <p>
                <span style={{ color: "#fff", fontWeight: "bold" }}>
                  Hey, Gabriel Fono, an engineer with a passion for teaching and
                  building stuff on the web.
                </span>{" "}
                <span>
                  {" "}
                  Gabriel is a dedicated Full-Time Software Engineer at
                </span>
                <span style={{ color: "#fff", fontWeight: "bold" }}>
                  {" "}
                  Booz Allen Hamilton,
                </span>
                <span> working remotely.</span>
                <br />
                He was born in a lively urban area in Cameroon and was raised by
                his grandmother. Even at a young age, he displayed a keen
                interest and fascination with{" "}
                <span style={{ color: "#fff", fontWeight: "bold" }}>
                  Mathematics
                </span>{" "}
                and{" "}
                <span style={{ color: "#fff", fontWeight: "bold" }}>
                  Science
                </span>
                , along with a passionate enthusiasm for{" "}
                <span style={{ color: "#fff", fontWeight: "bold" }}>
                  Video Games
                </span>
                .
                <br /> Gabriel pursued his academic journey in{" "}
                <span style={{ color: "#fff", fontWeight: "bold" }}>
                  Civil Engineering
                </span>
                <span>
                  {" "}
                  at a private institution, ultimately graduating with a degree
                  in
                </span>
                <span style={{ color: "#fff", fontWeight: "bold" }}>
                  {" "}
                  Civil Engineering{" "}
                </span>
                from{" "}
                <span style={{ color: "#fff", fontWeight: "bold" }}>
                  Institut Universitaire de la Cote{" "}
                </span>
                in July 2015 In <span> africa </span>(Cameroon). <br></br>
                Gabriel took part in various{" "}
                <span style={{ color: "#fff", fontWeight: "bold" }}>
                  Mathematics
                </span>{" "}
                and{" "}
                <span style={{ color: "#fff", fontWeight: "bold" }}>
                  Physics{" "}
                </span>
                competitions during high school and college.
                <span
                  style={{
                    fontSize: "10px",
                    width: "1% !important",
                    height: "1%!important",
                  }}
                >
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src={cameroonFlag}
                  />
                </span>{" "}
                <br />
                Later, he started a career as a{" "}
                <span style={{ color: "#fff", fontWeight: "bold" }}>
                  Structural Engineer
                </span>{" "}
                at an international company. His responsibilities included using
                civil engineering software to design and calculate
                infrastructure.
                <br />
                In 2016, he achieved a milestone by winning the{" "}
                <span style={{ color: "#fff", fontWeight: "bold" }}>
                  American Diversity Visa (DV) Lottery program
                </span>
                <span>
                  , a well-known program that helps many people move to the USA.
                  Upon arriving in the USA, Gabriel underwent a remarkable
                  career shift to software engineering. Despite financial
                  constraints that prevented him from enrolling in formal
                  education, he embarked on a rigorous self-taught journey,
                  utilizing online resources.
                </span>
                <br />{" "}
                <span>
                  During this self-guided learning, Gabriel encountered
                  challenges navigating through various online platforms to find
                  suitable courses and instructors. A year later, his passion
                  for full-stack development took root, inspiring him to
                  conceptualize a platform that streamlines access to resources,
                  tools, and expert instructors for individuals seeking to
                  switch to tech or enhance their tech skills. This initiative
                  is to help other people find good learning materials more
                  easily.
                </span>
                <br />
                <span>
                  Gabriel's passion also includes cybersecurity, which he
                  explores in his spare time through self-directed studies.
                  Recently, he achieved another significant milestone by
                  becoming a
                </span>
                <span style={{ color: "#fff", fontWeight: "bold" }}>
                  {" "}
                  US Citizen.
                </span>
                <br />
                <span
                  style={{
                    display: "block",
                    margin: "2rem 0 ",
                    fontSize: "10px",
                    width: "1% !important",
                    height: "1%!important",
                  }}
                >
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src={usaFlag}
                  />
                </span>{" "}
                With the rise of{" "}
                <span style={{ color: "#fff", fontWeight: "bold" }}>
                  Artificial Intelligence
                </span>{" "}
                <span>and </span>{" "}
                <span style={{ color: "#fff", fontWeight: "bold" }}>
                  Machine Learning ,
                </span>
                <span>
                  Gabriel strongly believes that combining knowledge in both{" "}
                </span>
                <span style={{ color: "#fff", fontWeight: "bold" }}>
                  CyberSecurity
                </span>{" "}
                <span> and </span>
                <span style={{ color: "#fff", fontWeight: "bold" }}>
                  SoftwareEngineering
                </span>{" "}
                is crucial for shaping the future. This strong belief drives his
                dedication to building a platform that brings these fields
                together. If you're an aspiring tech switcher seeking
                comprehensive resources, your search ends here.
                <br />
                Gabriel's passion for teaching shines through as he's currently
                crafting a course to lead people into the world of software
                engineering. His previous work mentoring individuals one-on-one
                adds to his commitment in this educational pursuit.
                <br />
                If your your goals is to join a community that shares knowledge
                about Cyber Security and Software Engineering, you are welcome{" "}
                <br />
                We have Tech tutorials from real professionals engineers, blogs
                about successful Startup, SASS and others , forums ,and
                thousands of profiles you could reach out.
                <br />
                Welcome to Hackersdev
              </p>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};
export default Team;
