import React, { useContext } from "react";
import Title from "../components/Title";
import Layout from "../components/Layout";
import Banner from "../components/Banner";
import Hero from "../components/Hero";

import Tutorial from "../components/Tutorial";
import { blogContext } from "../App";
import Loading from "../components/Loading";
import DescriptionAuthor from "../components/DescriptionAuthor";
import Email from "../components/Email";
import StyledHero from "../components/StyledHero";
import Seo from "../components/Seo";

const Tutorials = () => {
  const { tutorials, loadingTutorial } = useContext(blogContext);

  return (
    <>
      <Seo />
      <Layout>
        <StyledHero img="https://res.cloudinary.com/ground-service-international/image/upload/v1716675031/ratemymajor_communication.jpg">
          <Banner
            title="SoftwareIngineering and CyberSecurity practical tutorials"
            subtitle="we work hard to provide you the best well-explained tech-tutorials ever"
          ></Banner>
        </StyledHero>
        <section className="section">
          <Title title="access all our practical tutorials " specificTitle />

          <div className=" global-container-position-blog  ">
            {loadingTutorial && (
              <Loading title="all tutorials are currently loading ..." />
            )}
            <div className="section-center">
              {tutorials.map((tutorial) => {
                return <Tutorial key={tutorial.id} {...tutorial} />;
              })}
            </div>
            <div className="section-position-email">
              <Email />
              <DescriptionAuthor />
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default Tutorials;
