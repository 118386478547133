import React, { useState, useContext, useRef, useEffect } from "react";
import Layout from "../components/Layout";
import Title from "../components/Title";
import { Link } from "react-router-dom";

import BannerAffiliate from "../components/BannerAffiliate";
import Share from "../components/Share";
import DescriptionAuthor from "../components/DescriptionAuthor";
import { useAuth0 } from "@auth0/auth0-react";
import { blogContext } from "../App";
import ParentComments from "../components/ParentComments";
import AddParentComments from "../components/AddParentComments";
import Loading from "../components/Loading";
import { withRouter } from "react-router-dom";
import Seo from "../components/Seo";

const SingleBlog = (props) => {
  const [value, setValue] = useState(0);
  const { user, isAuthenticated, loginWithRedirect } = useAuth0();
  const slug = props.match.params.slug;

  const {
    getUniqueBlog,
    RemoveCurrentBlog,
    comments,
    postLike,
    allCommentCounts,
    totalLike,
    likes,
    updateTime,
    calculateRelativeTime,
  } = useContext(blogContext);
  const revealRefs = useRef([]);
  const addToRefs = (el) => {
    if (el && !revealRefs.current.includes(el)) {
      revealRefs.current.push(el);
    }
    return revealRefs.current;
  };
  const navigation = useRef([]);
  const addToNavigation = (el) => {
    if (el && !navigation.current.includes(el)) {
      navigation.current.push(el);
    }
    return navigation.current;
  };
  useEffect(() => {
    window.addEventListener("scroll", () => {
      let current = "";
      let scrollY = window.scrollY;
      const result = addToRefs();
      const result2 = addToNavigation();

      result.forEach((item, index) => {
        const sectionTop = item.offsetTop;
        const sectionHeight = item.clientHeight;

        if (
          index === 0 &&
          scrollY >= 0 &&
          scrollY <= sectionTop + sectionHeight
        ) {
          current = item.getAttribute("id");
        }
        if (index >= 1 && scrollY > 0 && scrollY >= sectionTop) {
          current = item.getAttribute("id");
        }
      });

      result2.forEach((item) => {
        item.classList.remove("navigation-activeClass");

        if (item.classList.contains(current)) {
          item.classList.add("navigation-activeClass");
        }
      });
    });
  }, []);

  const [relativeTime, setRelativeTime] = useState(null); // Initialize with null or any default value

  const singleblog = getUniqueBlog(slug);
  const removecurrentblog = RemoveCurrentBlog(slug);
  useEffect(() => {
    if (singleblog && singleblog.updated_at) {
      const intervalId = setInterval(() => {
        const updatedDate = new Date(singleblog.updated_at);
        const relativeTime = calculateRelativeTime(updatedDate);
        setRelativeTime(relativeTime);
      }, 1000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [singleblog]);

  if (!singleblog) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "100vh",
        }}
        className="error"
      >
        <h3>Post is loading...</h3>
        <p>Please Wait ...</p>
        <Loading />
      </div>
    );
  }
  let {
    title,
    category,
    titleImage,
    quote,
    quoteAuthor,
    introDesc,
    bodyDesc,
    conclusionDesc,
    sommaire,
    quickTips,
    created_at,
    updated_at,
    bloggerName,
    bloggerTitle,
    bloggerDescription,
    bloggerPhoto,
    bloggerFacebookLink,
    bloggerInstagramLink,
    bloggerYoutubeLink,
    bloggerTwitterLink,
    bloggerGithubLink,
    bloggerPersonalSiteLink,
    bloggerProfileEnabled,
    SeoData,
  } = singleblog;

  let time = new Date(created_at).toLocaleDateString("en-US").split("/");

  let currentTime = `${updateTime(time[0])} ${time[1]}, ${time[2]}`;

  const addDash = (uniqueId) => {
    const str = uniqueId.toLowerCase().split(" ").join("-").trim();
    return str;
  };
  const convertTitle = (uniqueId) => {
    const str = uniqueId.toUpperCase();
    return str;
  };
  const sommary = (sommaire) => {
    return sommaire.map((item, index) => {
      const { id, title } = item;

      return (
        <li key={title}>
          <a
            ref={addToNavigation}
            className={`  ${addDash(title)}  ${
              index === value && `navigation-activeClass`
            }`}
            onClick={() => setValue(index)}
            title={title}
            key={id}
            href={`#${addDash(title)}`}
          >
            {" "}
            {convertTitle(title)}
          </a>
        </li>
      );
    });
  };
  const introduction = () => {
    const Intro = introDesc.map((uniqueIntro) => {
      const { id, desc, image, link, textLink } = uniqueIntro;
      return (
        <div key={id}>
          <p className="description-paragraph">
            {desc} {link && <a href={link}> {textLink}</a>}
          </p>
          {image && (
            <div className="container-blogpost">
              <img src={`${image.url}`} />
            </div>
          )}
        </div>
      );
    });
    return Intro;
  };
  const body = () => {
    const bodyIntro = bodyDesc.map((uniquebody) => {
      const { id, subtitle, subtitleImage, textImage } = uniquebody;

      const TextImage = () => {
        return textImage.map((obj) => {
          const { id, desc, image, link, textLink } = obj;
          return (
            <div key={id}>
              <p className="description-paragraph">
                {desc} <a href={link}> {textLink}</a>
              </p>

              {image && (
                <div className="container-blogpost">
                  <img src={`${image.url}`} />
                </div>
              )}
            </div>
          );
        });
      };

      return (
        <div ref={addToRefs} id={addDash(subtitle)} key={id}>
          <h2 className="subtitle-description">{subtitle}</h2>
          {TextImage()}
          {subtitleImage && (
            <div className="container-blogpost">
              <img src={`${subtitleImage.url}`} />
            </div>
          )}
        </div>
      );
    });
    return bodyIntro;
  };

  const conclusion = () => {
    const conclusionIntro = conclusionDesc.map((item) => {
      const { desc, id, image, textLink, link } = item;
      return (
        <>
          <p key={id} className="description-paragraph">
            {desc}
            <a href={link}> {textLink}</a>
          </p>
          {image && (
            <div className="container-blogpost">
              <img src={`${image.url}`} />
            </div>
          )}
        </>
      );
    });
    return conclusionIntro;
  };

  const quickLinks = () => {
    const quickIntro = quickTips.map((tips) => {
      const { id, desc, link, textLink } = tips;
      return (
        <p key={id} className="description-paragraph">
          {desc}
          <a href={link}> {textLink} </a>
        </p>
      );
    });
    return quickIntro;
  };
  const allBlog = (blogs) => {
    return blogs.map((blog) => {
      const { id, titleImage, title, slug } = blog;

      return (
        <Link key={id} to={`/blogs/${slug}`}>
          <div className="suggestion-blog single-blog">
            <p>{title}</p>
            <img src={`${titleImage.url}`} alt="blog post" />
          </div>
        </Link>
      );
    });
  };

  return (
    <>
      <Seo seoData={SeoData} />
      <Layout>
        <section className="section ">
          <div className=" global-container-position-blog">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "200px",
                height: "200px",
                marginRight: "5%",
                position: " sticky",
                top: "200px",
              }}
              className="desktop-upvotes-comments"
            >
              <div
                style={{
                  width: "200px",
                  height: "100px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: "6px",
                  background: "white",
                  opacity: ".5",
                  padding: "5px",
                }}
              >
                {!isAuthenticated ? (
                  <span
                    title="click to upvote"
                    onClick={() => loginWithRedirect()}
                    style={{ color: "black", cursor: "pointer" }}
                  >
                    {parseInt(totalLike(likes, singleblog.title, "blogs")) > 1
                      ? " Upvotes:"
                      : " Upvote:"}
                    <span
                      title="click to upvote"
                      style={{ margin: "0", marginLeft: "5px" }}
                      className="btn-primary"
                    >
                      {" "}
                      {totalLike(likes, title, category) === 0
                        ? `👍 ${totalLike(likes, singleblog.title, "blogs")}`
                        : totalLike(likes, title, category)}
                    </span>
                  </span>
                ) : (
                  <span
                    onClick={() => postLike(singleblog.title, "blogs")}
                    style={{ color: "black", cursor: "pointer" }}
                    title="click to upvote"
                  >
                    {parseInt(totalLike(likes, singleblog.title, "blogs")) > 1
                      ? " Upvotes:"
                      : " Upvote:"}
                    <span
                      style={{ margin: "0", marginLeft: "5px" }}
                      className="btn-primary"
                      title="click to upvote"
                    >
                      {" "}
                      {totalLike(likes, title, category) === 0
                        ? `👍 ${totalLike(likes, singleblog.title, "blogs")}`
                        : totalLike(likes, title, category)}
                    </span>
                  </span>
                )}

                <a
                  href={`/blogs/${slug}#comments`}
                  style={{
                    display: "flex",

                    alignItems: "center",
                  }}
                  title="add a comment"
                >
                  <svg
                    width={30}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    id="ember1825"
                    title="add a comment"
                  >
                    <path d="M12 1c-6.338 0-12 4.226-12 10.007 0 2.05.738 4.063 2.047 5.625.055 1.83-1.023 4.456-1.993 6.368 2.602-.47 6.301-1.508 7.978-2.536 9.236 2.247 15.968-3.405 15.968-9.457 0-5.812-5.701-10.007-12-10.007zm0 14h-6v-1h6v1zm6-3h-12v-1h12v1zm0-3h-12v-1h12v1z"></path>
                  </svg>
                  <span
                    style={{
                      display: "inline-block",
                      color: "black",
                      textWrap: "no-wrap",
                      marginLeft: "5px",
                    }}
                  >
                    {allCommentCounts(comments, singleblog.title, "blogs") ===
                    0 ? (
                      <span>add comment 🤔</span>
                    ) : allCommentCounts(comments, singleblog.title, "blogs") >
                      1 ? (
                      `${allCommentCounts(
                        comments,
                        singleblog.title,
                        "blogs"
                      )} comments`
                    ) : (
                      `${allCommentCounts(
                        comments,
                        singleblog.title,
                        "blogs"
                      )} comment`
                    )}
                  </span>
                </a>
              </div>
            </div>

            <div className="all-container-blogpost">
              <Title title={title} />
              <div className="all-container-blogpost-update-blog-author">
                <span className="all-container-blogpost-update-blog-author-profile">
                  <img
                    src="https://res.cloudinary.com/ground-service-international/image/upload/v1646517895/gabriel_3c28de1c3a.jpg"
                    alt="founder picture"
                  ></img>
                </span>
                <span className="all-container-blogpost-update-blog-author-name">
                  Gabriel
                </span>
                <span className="all-container-blogpost-update-blog-author-separator"></span>
                <span className="all-container-blogpost-update-blog-author-date ">
                  Last Updated : {relativeTime}
                </span>
              </div>
              <Share description="Please share on your social " />

              <span className="all-container-blogpost-update-blog-author-date-mobile">
                Last Updated : {relativeTime}
              </span>
              <div
                style={{
                  display: "flex",
                  width: "fit-content",
                  borderRadius: "6px",
                  background: "white",
                  opacity: ".5",
                  marginTop: "1rem",
                  padding: "0 20px",
                }}
                className="mobile-upvotes-comments"
              >
                {!isAuthenticated ? (
                  <span
                    onClick={() => loginWithRedirect()}
                    style={{ color: "black", cursor: "pointer" }}
                    title="click to upvote"
                  >
                    {parseInt(totalLike(likes, singleblog.title, "blogs")) > 1
                      ? " Upvotes:"
                      : " Upvote:"}
                    <span
                      style={{ margin: "0", marginLeft: "5px" }}
                      className="btn-primary"
                      title="click to upvote"
                    >
                      {" "}
                      {totalLike(likes, title, category) === 0
                        ? `👍 ${totalLike(likes, singleblog.title, "blogs")}`
                        : totalLike(likes, title, category)}
                    </span>
                  </span>
                ) : (
                  <span
                    onClick={() => postLike(singleblog.title, "blogs")}
                    style={{ color: "black", cursor: "pointer" }}
                    title="click to upvote"
                  >
                    {parseInt(totalLike(likes, singleblog.title, "blogs")) > 1
                      ? " Upvotes:"
                      : " Upvote:"}
                    <span
                      style={{ margin: "0", marginLeft: "5px" }}
                      className="btn-primary"
                      title="click to upvote"
                    >
                      {" "}
                      {totalLike(likes, title, category) === 0
                        ? `👍 ${totalLike(likes, singleblog.title, "blogs")}`
                        : totalLike(likes, title, category)}
                    </span>
                  </span>
                )}

                <a
                  title="add a comment"
                  href={`/blogs/${slug}#comments`}
                  style={{
                    display: "flex",

                    alignItems: "center",
                  }}
                >
                  <svg
                    width={30}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    id="ember1825"
                  >
                    <path d="M12 1c-6.338 0-12 4.226-12 10.007 0 2.05.738 4.063 2.047 5.625.055 1.83-1.023 4.456-1.993 6.368 2.602-.47 6.301-1.508 7.978-2.536 9.236 2.247 15.968-3.405 15.968-9.457 0-5.812-5.701-10.007-12-10.007zm0 14h-6v-1h6v1zm6-3h-12v-1h12v1zm0-3h-12v-1h12v1z"></path>
                  </svg>
                  <span
                    style={{
                      display: "inline-block",
                      color: "black",
                      textWrap: "no-wrap",
                      marginLeft: "5px",
                    }}
                  >
                    {allCommentCounts(comments, singleblog.title, "blogs") ===
                    0 ? (
                      <span>add comment 🤔</span>
                    ) : allCommentCounts(comments, singleblog.title, "blogs") >
                      1 ? (
                      `${allCommentCounts(
                        comments,
                        singleblog.title,
                        "blogs"
                      )} comments`
                    ) : (
                      `${allCommentCounts(
                        comments,
                        singleblog.title,
                        "blogs"
                      )} comment`
                    )}
                  </span>
                </a>
              </div>

              <div className="container-blogpost">
                <img src={`${titleImage.url}`} />
              </div>
              <div>
                <p>
                  <q className="blockquote">{quote}</q>
                </p>
                <div>
                  <p className="author-quote"> {quoteAuthor}</p>
                </div>
                <div className="introduction-blog">{introduction()}</div>
                <div className="sommary-recap-article">
                  <ul>{sommary(sommaire)}</ul>
                </div>
                <div>
                  <div className="body-blog">{body()}</div>
                  <div>{conclusion()}</div>
                  <div> {quickLinks()}</div>
                  <div
                    style={{
                      width: "300px",
                      height: "300px",
                      margin: "2rem auto",
                      maxWidth: "98%",
                    }}
                  >
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                        borderRadius: "10px",
                      }}
                      src="https://www.notion.so/image/https%3A%2F%2Fmedia.giphy.com%2Fmedia%2FlD76yTC5zxZPG%2Fgiphy.gif?table=block&amp;id=183bd720-c76b-4f40-ae9b-c23d2763557d&amp;cache=v2"
                    ></img>
                  </div>

                  <BannerAffiliate
                    // date=" Feb 20, 2022"
                    date={relativeTime}
                    author="by Gabriel Fono"
                  />
                  <DescriptionAuthor
                    bloggerName={bloggerName}
                    bloggerTitle={bloggerTitle}
                    bloggerDescription={bloggerDescription}
                    bloggerPhoto={bloggerPhoto}
                    bloggerFacebookLink={bloggerFacebookLink}
                    bloggerInstagramLink={bloggerInstagramLink}
                    bloggerYoutubeLink={bloggerYoutubeLink}
                    bloggerTwitterLink={bloggerTwitterLink}
                    bloggerGithubLink={bloggerGithubLink}
                    bloggerPersonalSiteLink={bloggerPersonalSiteLink}
                    bloggerProfileEnabled={bloggerProfileEnabled}
                  />
                  <Share description="Please share on your social" />
                </div>
              </div>
            </div>
            <div className="section-position-email">
              <div className="section-position-email-table-of-content">
                <h4>Table of content</h4>
                <nav>
                  <ul>
                    {" "}
                    <ul>{sommary(sommaire)}</ul>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          {/* <Signup /> */}
        </section>
        <div className="all-suggestion-container">
          <div className="all-suggestion-container-related-border">
            <p></p>
          </div>

          <p className="all-suggestion-container-related-definition">
            Related Articles{" "}
          </p>
          <div className="suggestion-container">
            {allBlog(removecurrentblog)}
          </div>
        </div>
        <div className="parent-global-container-comments-user">
          <div className="global-container-comments-user__border_counterComments">
            <div className="global-container-comments-user_border_top"></div>
          </div>

          <AddParentComments
            category="blogs"
            title={singleblog.title}
            slug={singleblog.slug}
            type={null}
          />
          <ParentComments
            category="blogs"
            title={singleblog.title}
            uniqueId={singleblog.id}
            user={user}
            isAuthenticated={isAuthenticated}
            slug={singleblog.slug}
            type={null}
          />
        </div>
        <a
          href={`/blogs/${slug}#title`}
          style={{
            position: "fixed",
            right: "10px",
            float: "right",
            top: "90%",
            bottom: "0",

            height: "100vh",
            zIndex: "99999",
          }}
        >
          <svg
            style={{ cursor: "pointer" }}
            width="40"
            height="40"
            viewBox="0 0 40 40"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 20c0 11.028 8.972 20 20 20s20-8.972 20-20S31.028 0 20 0 0 8.972 0 20zm29.023 3.333-2.356 2.357L20 19.023l-6.667 6.667-2.356-2.357L20 14.31l9.023 9.023z"
              fill="#666"
              opacity="1"
              cursor="pointer"
              title="go back on the top"
            ></path>
          </svg>
        </a>
      </Layout>
    </>
  );
};

export default withRouter(SingleBlog);
