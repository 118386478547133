import React, { useState, useEffect, useContext } from "react";
import QuickLinks from "../Footer/QuickLinks";
import ImportantLinks from "../Footer/ImportantLinks";
import ContactUs from "../Footer/ContactUs";
import Subscribe from "../Footer/Subscribe";
import axios from "axios";
import { blogContext } from "../../App";

const Footer = () => {
  const initialValues = {
    email: "",
  };
  const [subscription, setSubscription] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [userEmail, setUserEmail] = useState({});
  const [error, setError] = useState(null);
  const [signed, setSigned] = useState("");

  const handleChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;

    setSubscription({ ...subscription, [name]: value });
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
    }
  }, [formErrors]);

  const validate = (users) => {
    const errors = {};

    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!users.email) {
      errors.email = "Email is required!!!";
    } else if (!regex.test(users.email)) {
      errors.email = "This is not the valid email format!";
    }

    return errors;
  };

  const handleSubscription = async (e) => {
    e.preventDefault();
    setError(null);
    setFormErrors(validate(subscription));

    setIsSubmit(true);

    let email = subscription.email;
    const api_url = `/api/memberAdd/${email}`;

    fetch(api_url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (data.title === "Member Exists") {
          setSigned("You are already signed up for this email  😥 service.");
        } else if (data.title === "") {
          setSubscription({
            email: "",
            subscribe: "",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);

        if (
          error instanceof Response &&
          (error.status === 400 || error.status === 500)
        ) {
          setError(
            "An error occurred with the server. Please try again later."
          );
        } else if (error.message === "Network response was not ok") {
          setError(
            "It couldd be a  network error . Please check your internet connection."
          );
        } else {
          setError("An unknown error occurred. Please try again later.");
        }
      });
  };

  return (
    <footer>
      <section className="section footer">
        <div className="container-new-letter">
          <p>Stay informed as hackersDev ...</p>
          <p>
            Join The Best Tech community of Software Engineers and CyberSecurity
            to learn and contribute
          </p>
          {Object.keys(userEmail).length !== 0 ? (
            <div className="successReview">{userEmail.message}</div>
          ) : (
            Object.keys(formErrors).length === 0 &&
            isSubmit && (
              <>
                <div style={{ textAlign: "center" }} className="successReview">
                  {signed.length > 0
                    ? signed
                    : "Thanks 😊 , We just added you on our mailing list"}
                </div>
              </>
            )
          )}
          <form className="new-letters" onSubmit={handleSubscription}>
            <input
              name="email"
              placeholder="Enter your email address  ..."
              type="email"
              value={subscription.email}
              onChange={handleChange}
            />
            <button type="submit">Join Today</button>
          </form>
          <p className="errorReview"> {formErrors.email}</p>
          <p className="errorReview"> {error}</p>
        </div>

        <div className="container-footer">
          <QuickLinks />
          <ImportantLinks />
          <ContactUs />
          <Subscribe />
        </div>
        <h4 className="copyright">
          copyright&copy;{new Date().getFullYear()}
          <span>hackersdev</span> all rights reserved
        </h4>
      </section>
    </footer>
  );
};

export default Footer;
