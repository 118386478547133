import React, { useState, useEffect, useContext, useRef, useMemo } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Share from "../components/Share";
import { blogContext } from "../App";
import { useAuth0 } from "@auth0/auth0-react";
import uuid from "react-uuid";

function AddParentComments({
  title,
  slug,
  uniqueId,
  category,
  datas,
  allData,
  type,
}) {
  const {
    allCommentCounts,
    comments,
    setComment,
    likes,
    setLike,
    postLike,
    totalLike,
  } = useContext(blogContext);
  const { user, isAuthenticated, loginWithRedirect } = useAuth0();
  const removeMessageUser = useRef();
  const handleRemoveMessage = () => {
    setTimeout(() => {
      if (removeMessageUser.current !== null) {
        removeMessageUser.current.style.display = "none";
      } else {
        return;
      }
    }, 1000);
  };

  const handleLogin = () => {
    // Store the current path in session storage
    sessionStorage.setItem("redirectPath", window.location.pathname);

    // Redirect to the login page
    loginWithRedirect();
  };

  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  let history = useHistory();

  const userInfo = {
    comment: "",
  };

  const [data, setData] = useState(userInfo);

  const handleComment = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };
  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
    }
  }, [formErrors]);
  const validate = (values) => {
    const errors = {};
    if (!values.comment) {
      errors.comment = "Your comment is empty";
    }
    return errors;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(data));
    let result = validate(data);
    setIsSubmit(true);
    if (Object.keys(result).length === 0) {
      axios
        .post("http://localhost:1337/Comments", {
          ...data,
          title: title,
          type: type,
          email: user.email ? user.email : "",
          // uniqueId: uniqueId,
          username: user.given_name ? user.given_name : user.nickname,
          image: user.picture,
          parentId: null,
          category: category,
        })
        .then(() => {
          setData({
            comment: "",
          });
          handleRemoveMessage();
          // history.push(`/${category}/${slug}`);

          axios
            .get("http://localhost:1337/comments")
            .then((response) => {
              return response.data;
            })
            .then((data) => {
              setComment(data);
              history.push({
                pathname: `/${category}/${slug}`,
                key: uuid(),
                state: {
                  datas: datas,
                  allData: allData,
                },
              });
            })
            .catch(function (error) {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log("An error occurred:", error.response);
        });
    } else {
      return;
    }
  };

  // let totalCountLike = likes
  //   .filter((like) => like.title === title && like.category === category)
  //   .reduce((accumulator, currentValue) => {
  //     return accumulator + currentValue.PostLike;
  //   }, 0);

  const memoChild = useMemo(() => {
    return (
      Object.keys(formErrors).length === 0 &&
      isSubmit && (
        <div ref={removeMessageUser} className="successReview">
          Thanks for your participation
        </div>
      )
    );
  }, [comments]);
  return (
    <div id="comments" className="parent-global-container-comments-user">
      <form className="global-container-comments-user " onSubmit={handleSubmit}>
        {memoChild}
        <span className="global-container-comments-user-authenticated">
          {isAuthenticated ? <img src={user.picture} /> : ""}
        </span>
        <textarea
          placeholder="Leave Your comment here and use https to share links for example https://hackersdev.com 📝 ..."
          value={data.comment}
          onChange={handleComment}
          name="comment"
        ></textarea>
        <p className="errorReview">{formErrors.comment}</p>
        {isAuthenticated ? (
          <button type="submit" className="btn-primary">
            post comment 📝
          </button>
        ) : (
          <button
            onClick={handleLogin}
            // title="you should login to comment"
            type="submit"
            className="btn-primary"
          >
            post comment 📝
          </button>
        )}
      </form>
    </div>
  );
}

export default AddParentComments;
