import React, { useContext, useState, useEffect, useRef } from "react";

import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import { vs2015 } from "react-syntax-highlighter/dist/esm/styles/hljs";
import CopyToClipboard from "react-copy-to-clipboard";
import { blogContext } from "../App";
import { Link, withRouter } from "react-router-dom";
import Layout from "../components/Layout";
import Title from "../components/Title";
import "highlight.js/styles/github.css";
import Loading from "../components/Loading";
import VideoPlayer from "../components/VideoPlayer";
import AddParentComments from "../components/AddParentComments";
import ParentComments from "../components/ParentComments";
import BannerAffiliate from "../components/BannerAffiliate";
import DescriptionAuthor from "../components/DescriptionAuthor";
import Share from "../components/Share";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";
import StyledHero from "../components/StyledHero";
import Seo from "../components/Seo";

const SingleTutorials = () => {
  const [copied, setCopied] = useState(false);
  const [value, setValue] = useState(0);
  const [relativeTime, setRelativeTime] = useState(null);
  const [tutorialData, setTutorialData] = useState(null);
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const { slug } = useParams();

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const {
    setLoadingSingleTutorialCategory,
    updateTime,
    totalLike,
    likes,
    comments,
    postLike,
    allCommentCounts,
    calculateRelativeTime,
  } = useContext(blogContext);

  const revealRefs = useRef([]);
  const addToRefs = (el) => {
    if (el && !revealRefs.current.includes(el)) {
      revealRefs.current.push(el);
    }
    return revealRefs.current;
  };

  const navigation = useRef([]);
  const addToNavigation = (el) => {
    if (el && !navigation.current.includes(el)) {
      navigation.current.push(el);
    }
    return navigation.current;
  };

  // Scroll event listener
  useEffect(() => {
    const handleScroll = () => {
      let current = "";
      let scrollY = window.scrollY;
      const result = addToRefs();
      const result2 = addToNavigation();

      result.forEach((item, index) => {
        const sectionTop = item.offsetTop;
        const sectionHeight = item.clientHeight;

        if (
          index === 0 &&
          scrollY >= 0 &&
          scrollY <= sectionTop + sectionHeight
        ) {
          current = item.getAttribute("id");
        }
        if (index >= 1 && scrollY > 0 && scrollY >= sectionTop) {
          current = item.getAttribute("id");
        }
      });

      result2.forEach((item) => {
        item.classList.remove("navigation-activeClass");

        if (item.classList.contains(current)) {
          item.classList.add("navigation-activeClass");
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Retrieve tutorial data from localStorage (or any other source)
  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("tutorialData"));
    if (storedData) {
      setTutorialData(storedData);
    }
  }, []);

  // Update relative time for singleTutorial
  useEffect(() => {
    if (tutorialData) {
      const singleTutorial = findIndexClick(tutorialData.datas, slug);

      if (singleTutorial && singleTutorial.updated_at) {
        const intervalId = setInterval(() => {
          const updatedDate = new Date(singleTutorial.updated_at);
          const relativeTime = calculateRelativeTime(updatedDate);
          setRelativeTime(relativeTime);
        }, 1000);
        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
      }
    }
  }, [tutorialData, slug, calculateRelativeTime]);

  if (!tutorialData) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "100vh",
        }}
        className="error"
      >
        <h3>Post is loading...</h3>
        <p>Please Wait ...</p>
        <Loading />
      </div>
    );
  }

  const tutorial = tutorialData.datas;
  const allData = tutorialData.allData;

  const findIndexClick = (array, id) => {
    return array.find((item) => item.title.trim() === id.trim());
  };

  const singleTutorial = findIndexClick(tutorial, slug);

  if (!singleTutorial) {
    return <div>Tutorial page not found</div>;
  }

  const addDash = (uniqueId) => {
    const str = uniqueId.toLowerCase().split(" ").join("-").trim();
    return str;
  };
  const convertTitle = (uniqueId) => {
    const str = uniqueId.toUpperCase();
    return str;
  };

  const sommary = (sommaire) => {
    return sommaire.map((item, index) => {
      const { id, title } = item;
      return (
        <li key={title}>
          <a
            ref={addToNavigation}
            className={`  ${addDash(title)}  ${
              index === value && `navigation-activeClass`
            }`}
            onClick={() => setValue(index)}
            title={title}
            key={id}
            href={`#${addDash(title)}`}
          >
            {" "}
            {convertTitle(title)}
          </a>
        </li>
      );
    });
  };

  if (!singleTutorial) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "100vh",
        }}
        className="error"
      >
        <h3>Tutorial is loading...</h3>
        <p>Please Wait ...</p>
        <Loading />
      </div>
    );
  }

  let {
    title,
    image,
    src,
    introduction,
    sommaire,
    body,
    recap,
    conclusion,
    created_at,
    date,
    category,
    SeoData,
  } = singleTutorial;

  if (singleTutorial != null) {
    setLoadingSingleTutorialCategory(true);
  }
  title = title.replaceAll("-", " ");
  let time = date.split("-");
  let currentTime = `${updateTime(time[2])} ${time[1]}, ${time[0]}`;

  const intro = (introduction) => {
    return introduction.map((item) => {
      const { id, desc, link, linkText } = item;

      return (
        <div key={id}>
          <p className="description-paragraph">
            {desc} {link && <Link to={link}> {linkText}</Link>}
          </p>
        </div>
      );
    });
  };
  const conclu = (conclusion) => {
    return conclusion.map((item) => {
      const { id, desc, link, linkText, title } = item;

      return (
        <div key={id}>
          <p className="description-paragraph">
            {desc}{" "}
            {(link && <Link to={link}> {linkText}</Link>) ||
              (src && <a href={link}> {linkText} </a>)}
          </p>
          {title && <p>{title}</p>}
        </div>
      );
    });
  };

  const bodyTutorial = () => {
    return body.map((item, index) => {
      let { title, description, code, src, image, id } = item;

      const descTutorial = () => {
        return description.map((item) => {
          const { desc, link, id, linkText, title } = item;
          return (
            <p
              style={{ color: "#9cb3c9" }}
              key={id}
              className="description-paragraph"
            >
              {desc}{" "}
              {(link && <a href={link}> {linkText}</a>) ||
                (src && <a href={src}> {linkText} </a>)}
            </p>
          );
        });
      };
      return (
        <div>
          <div ref={addToRefs} id={addDash(title)} key={id}>
            <h2 className="subtitle-description"> {title}</h2>
          </div>

          {descTutorial()}
          <div className="global-container-video-image-title">
            {(src && (
              <div className="video-container-tutorial">
                <VideoPlayer videoUrl={src} />
              </div>
            )) ||
              (image && (
                <div className="global-container-video-image-title__image">
                  <img src={`${image.url}`} />
                </div>
              )) ||
              (code && (
                <div className="code-block">
                  <CopyToClipboard text={code} onCopy={handleCopy}>
                    <button className="copy-button">
                      {copied ? "Copied!" : "Copy"}
                    </button>
                  </CopyToClipboard>
                  <SyntaxHighlighter language="javascript" style={vs2015}>
                    {code}
                  </SyntaxHighlighter>
                </div>
              )) || (
                <div className=" global-container-video-image-title__title ">
                  <h1>what is the easier wayt to learn how to code</h1>
                </div>
              )}
            <p className="global-container-video-image-title__description">
              watch demo tutorial
            </p>
          </div>
        </div>
      );
    });
  };

  const allTutorial = (tutorials) => {
    return tutorials.map((blog) => {
      const { id, image, title, slug } = blog;

      return (
        <Link key={id} to={`/tutorials/${slug}`}>
          <div className="suggestion-blog">
            <p>{title}</p>
            <img src={`${image.url}`} alt="tutorial post" />
          </div>
        </Link>
      );
    });
  };

  return (
    <>
      <Seo seoData={SeoData} />
      <Layout>
        <section className="section">
          <div className=" global-container-position-blog  ">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "200px",
                height: "200px",
                marginRight: "5%",
                position: " sticky",
                top: "200px",
              }}
              className="desktop-upvotes-comments"
            >
              <div
                style={{
                  width: "200px",
                  height: "100px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: "6px",
                  background: "white",
                  opacity: ".5",
                  padding: "5px",
                }}
              >
                {!isAuthenticated ? (
                  <span
                    title="click to upvote"
                    onClick={() => loginWithRedirect()}
                    style={{ color: "black", cursor: "pointer" }}
                  >
                    {parseInt(
                      totalLike(likes, singleTutorial.title, "allTutorials")
                    ) > 1
                      ? " Upvotes:"
                      : " Upvote:"}
                    <span
                      title="click to upvote"
                      style={{ margin: "0", marginLeft: "5px" }}
                      className="btn-primary"
                    >
                      {" "}
                      {totalLike(
                        likes,
                        singleTutorial.title,
                        "allTutorials"
                      ) === 0
                        ? `👍 ${totalLike(
                            likes,
                            singleTutorial.title,
                            "alltutorials"
                          )}`
                        : totalLike(
                            likes,
                            singleTutorial.title,
                            "allTutorials"
                          )}
                    </span>
                  </span>
                ) : (
                  <span
                    onClick={() =>
                      postLike(singleTutorial.title, "allTutorials")
                    }
                    style={{ color: "black", cursor: "pointer" }}
                    title="click to upvote"
                  >
                    {parseInt(
                      totalLike(likes, singleTutorial.title, "allTutorials")
                    ) > 1
                      ? " Upvotes:"
                      : " Upvote:"}
                    <span
                      style={{ margin: "0", marginLeft: "5px" }}
                      className="btn-primary"
                      title="click to upvote"
                    >
                      {" "}
                      {totalLike(likes, singleTutorial.title, category) === 0
                        ? `👍 ${totalLike(
                            likes,
                            singleTutorial.title,
                            "allTutorials"
                          )}`
                        : totalLike(likes, singleTutorial.title, category)}
                    </span>
                  </span>
                )}

                <a
                  href={`/alltutorials/${slug}#comments`}
                  style={{
                    display: "flex",

                    alignItems: "center",
                  }}
                  title="add a comment"
                >
                  <svg
                    width={30}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    id="ember1825"
                    title="add a comment"
                  >
                    <path d="M12 1c-6.338 0-12 4.226-12 10.007 0 2.05.738 4.063 2.047 5.625.055 1.83-1.023 4.456-1.993 6.368 2.602-.47 6.301-1.508 7.978-2.536 9.236 2.247 15.968-3.405 15.968-9.457 0-5.812-5.701-10.007-12-10.007zm0 14h-6v-1h6v1zm6-3h-12v-1h12v1zm0-3h-12v-1h12v1z"></path>
                  </svg>
                  <span
                    style={{
                      display: "inline-block",
                      color: "black",
                      textWrap: "no-wrap",
                      marginLeft: "5px",
                    }}
                  >
                    {allCommentCounts(
                      comments,
                      singleTutorial.title,
                      "allTutorials"
                    ) === 0 ? (
                      <span>add comment 🤔</span>
                    ) : allCommentCounts(
                        comments,
                        singleTutorial.title,
                        "allTutorials"
                      ) > 1 ? (
                      `${allCommentCounts(
                        comments,
                        singleTutorial.title,
                        "allTutorials"
                      )} comments`
                    ) : (
                      `${allCommentCounts(
                        comments,
                        singleTutorial.title,
                        "allTutorials"
                      )} comment`
                    )}
                  </span>
                </a>
              </div>
            </div>

            <div className="  all-container-blogpost">
              <Title title={title} />

              <div className="all-container-blogpost-update-blog-author">
                <span className="all-container-blogpost-update-blog-author-profile">
                  <img
                    src="https://res.cloudinary.com/ground-service-international/image/upload/v1646517895/gabriel_3c28de1c3a.jpg"
                    alt="founder picture"
                  ></img>
                </span>
                <span className="all-container-blogpost-update-blog-author-name">
                  Gabriel
                </span>
                <span className="all-container-blogpost-update-blog-author-separator"></span>
                <span className="all-container-blogpost-update-blog-author-date ">
                  Last Updated : {relativeTime}
                </span>
              </div>
              <Share description="Please share on your social" />
              <span className="all-container-blogpost-update-blog-author-date-mobile">
                Last Updated : {relativeTime}
              </span>
              <div
                style={{
                  display: "flex",
                  width: "fit-content",
                  borderRadius: "6px",
                  background: "white",
                  opacity: ".5",
                  marginTop: "1rem",
                  padding: "0 20px",
                }}
                className="mobile-upvotes-comments"
              >
                {!isAuthenticated ? (
                  <span
                    onClick={() => loginWithRedirect()}
                    style={{ color: "black", cursor: "pointer" }}
                    title="click to upvote"
                  >
                    {parseInt(
                      totalLike(likes, singleTutorial.title, "alltutorials")
                    ) > 1
                      ? " Upvotes:"
                      : " Upvote:"}
                    <span
                      style={{ margin: "0", marginLeft: "5px" }}
                      className="btn-primary"
                      title="click to upvote"
                    >
                      {" "}
                      {totalLike(likes, title, category) === 0
                        ? `👍 ${totalLike(
                            likes,
                            singleTutorial.title,
                            "allTutorials"
                          )}`
                        : totalLike(likes, singleTutorial.title, category)}
                    </span>
                  </span>
                ) : (
                  <span
                    onClick={() =>
                      postLike(singleTutorial.title, "allTutorials")
                    }
                    style={{ color: "black", cursor: "pointer" }}
                    title="click to upvote"
                  >
                    {parseInt(
                      totalLike(likes, singleTutorial.title, "allTutorials")
                    ) > 1
                      ? " Upvotes:"
                      : " Upvote:"}
                    <span
                      style={{ margin: "0", marginLeft: "5px" }}
                      className="btn-primary"
                      title="click to upvote"
                    >
                      {" "}
                      {totalLike(likes, singleTutorial.title, category) === 0
                        ? `👍 ${totalLike(
                            likes,
                            singleTutorial.title,
                            "allTutorials"
                          )}`
                        : totalLike(likes, singleTutorial.title, category)}
                    </span>
                  </span>
                )}

                <a
                  title="add a comment"
                  href={`/alltutorials/${slug}#comments`}
                  style={{
                    display: "flex",

                    alignItems: "center",
                  }}
                >
                  <svg
                    width={30}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    id="ember1825"
                  >
                    <path d="M12 1c-6.338 0-12 4.226-12 10.007 0 2.05.738 4.063 2.047 5.625.055 1.83-1.023 4.456-1.993 6.368 2.602-.47 6.301-1.508 7.978-2.536 9.236 2.247 15.968-3.405 15.968-9.457 0-5.812-5.701-10.007-12-10.007zm0 14h-6v-1h6v1zm6-3h-12v-1h12v1zm0-3h-12v-1h12v1z"></path>
                  </svg>
                  <span
                    style={{
                      display: "inline-block",
                      color: "black",
                      textWrap: "no-wrap",
                      marginLeft: "5px",
                    }}
                  >
                    {allCommentCounts(
                      comments,
                      singleTutorial.title,
                      "allTutorials"
                    ) === 0 ? (
                      <span>add comment bro 🤔</span>
                    ) : allCommentCounts(
                        comments,
                        singleTutorial.title,
                        "allTutorials"
                      ) > 1 ? (
                      `${allCommentCounts(
                        comments,
                        singleTutorial.title,
                        "allTutorials"
                      )} comments`
                    ) : (
                      `${allCommentCounts(
                        comments,
                        singleTutorial.title,
                        "allTutorials"
                      )} comment`
                    )}
                  </span>
                </a>
              </div>
              <div className="global-container-video-image-title">
                {(src && (
                  <div className="video-container-tutorial">
                    <VideoPlayer videoUrl={src} />
                  </div>
                )) ||
                  (image && (
                    <div className="global-container-video-image-title__image">
                      <img src={`${image.url}`} />
                    </div>
                  )) || (
                    <div className=" global-container-video-image-title__title ">
                      <h1>what is the easier wayt to learn how to code</h1>
                    </div>
                  )}
                <p className="global-container-video-image-title__description">
                  watch demo tutorial
                </p>
              </div>

              <div className="introduction-blog">{intro(introduction)}</div>
              <div className="sommary-recap-article">
                <div>
                  <p>{recap}</p>
                  <ul>{sommary(sommaire)}</ul>
                </div>
                <div>
                  <div className="body-blog"> {bodyTutorial()}</div>
                  <div className="introduction-blog">{conclu(conclusion)}</div>
                  <BannerAffiliate
                    date=" Feb 22, 2022"
                    author="by Gabriel Fono"
                  />
                  <DescriptionAuthor />
                </div>
              </div>
            </div>
            <div className="section-position-email">
              {/* <Email /> */}
              <div className="section-position-email-table-of-content">
                <h4>Table of content</h4>
                <nav>
                  <ul>
                    {" "}
                    <ul>{sommary(sommaire)}</ul>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </section>
        <div className="all-suggestion-container">
          <div className="all-suggestion-container-related-border">
            <p></p>
          </div>

          <p className="all-suggestion-container-related-definition">
            Related Tutorials{" "}
          </p>
          <div className="suggestion-container">{allTutorial(allData)}</div>
        </div>
        <div className="parent-global-container-comments-user">
          <div className="global-container-comments-user__border_counterComments">
            <div className="global-container-comments-user_border_top"></div>
          </div>

          <AddParentComments
            category="allTutorials"
            title={singleTutorial.title}
            uniqueId={singleTutorial.id}
            slug={slug}
            /* data={info.data}
          allData={info.allData} */
            type={null}
          />
          <ParentComments
            category="allTutorials"
            title={singleTutorial.title}
            uniqueId={tutorial.id}
            slug={slug}
            type={null}
          />
        </div>
      </Layout>
    </>
  );
};

export default withRouter(SingleTutorials);
