import React from "react";
import uuid from "react-uuid";
import SociauxLinks from "../constant/SociauxLinks";
 
function BloggerAuthorProfile() {
  const programmingLanguage = [
    "C++",
    "Java",
    "SpringBoot",
    "AWS",
    "MicroService",
    "Docker",
    "ReactJs",
    "Javascript",
    "HTML",
    "CSS",
    "POSTGRESQL",
    "MYSQL",
    "SQL",
  ];
  const tools = ["Docker", "Weblogic", "Gulp", "Webpack"];
  const result = (data) => {
    return data.map((item) => {
      if (item === data[data.length - 1]) {
        return (
          <span style={{ fontWeight: "bold" }} key={uuid()}>
            and {item}.
          </span>
        );
      }
      return (
        <span style={{ fontWeight: "bold" }} key={uuid()}>
          {item},{" "}
        </span>
      );
    });
  };
  return (
    <div className="section__blogging__aside_author">
      <div className="section__blogging__aside_author_container">
        <div className="section__blogging__aside_author_container_author">
          <img
            src="https://res.cloudinary.com/ground-service-international/image/upload/v1646517895/gabriel_3c28de1c3a.jpg"
            alt="founder picture"
          />
        </div>
        <div className="section__blogging__aside_author_container_author_title">
          <span>Gabriel Fono</span>
          <span>CEO, Founder, HackersDev</span>
          <a style={{ color: "blue" }} href="https://indiehackers.com">
            hackersdev.com
          </a>
        </div>
      </div>

      <p>
        Gabriel Fono is a remote{" "}
        <span style={{ fontWeight: "bold" }}>Full Stack Engineer</span> at{" "}
        <span style={{ fontWeight: "bold" }}>Booz Allen Hamilton</span>. He is
        skilled in programming languages such as : {result(programmingLanguage)}
        .In addition, Gabriel is skilled in tools such as: {result(tools)}
        <span>and many more</span>.<br></br>
        Joke aside , Gabriel loves coffee and he is also love sharing knowledge
        about software engineering and cyberSecurity.
      </p>
      <h5>Follow Gabriel here</h5>
      <div className="section__blogging__aside_author_socialLinks_position">
        <SociauxLinks />
      </div>
    </div>
  );
}

// Conditionally render BloggerProfile based on bloggerProfileEnabled
//              {bloggerProfileEnabled ? <BloggerProfile /> : <BloggerProfile />}

export default BloggerAuthorProfile;
