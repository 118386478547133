import React, { useContext } from "react";
import Title from "./Title";
import Loading from "../components/Loading";
import Blog from "./Blog";
import { blogContext } from "../App";
import { Link } from "react-router-dom";

const Blogs = (props) => {
  const { featuredBlog, loading } = useContext(blogContext);
  const featured = featuredBlog();
  const { title, showLink } = props;
  if (loading) {
    return <Loading title=' all blogs are currently loading ...' />;
  }
  return (
    <section className='section'>
      <Title title={title} specificTitle />

      <div className='section-center'>
        {featured.map((blog) => {
          return <Blog key={blog.id} {...blog} />;
        })}
      </div>
      {showLink && (
        <Link to='/blog' className=' center-btn btn-primary'>
          explore blogs
        </Link>
      )}
    </section>
  );
};

export default Blogs;
