import React from "react";

import BloggerProfile from "./BloggerProfile.js";
import BloggerAuthorProfile from "./BloggerAuthorProfile.js";

function DescriptionAuthor(props) {
  const {
    bloggerName,
    bloggerTitle,
    bloggerDescription,
    bloggerPhoto,
    bloggerFacebookLink,
    bloggerInstagramLink,
    bloggerYoutubeLink,
    bloggerTwitterLink,
    bloggerGithubLink,
    bloggerPersonalSiteLink,
    bloggerProfileEnabled,
  } = props;
  // Conditionally render the BloggerProfile component based on bloggerProfileEnabled
  return bloggerProfileEnabled ? (
    <BloggerProfile
      bloggerName={bloggerName}
      BloggerTitle={bloggerTitle}
      bloggerDescription={bloggerDescription}
      bloggerPhoto={bloggerPhoto}
      bloggerFacebookLink={bloggerFacebookLink}
      bloggerInstagramLink={bloggerInstagramLink}
      bloggerYoutubeLink={bloggerYoutubeLink}
      bloggerTwitterLink={bloggerTwitterLink}
      bloggerGithubLink={bloggerGithubLink}
      bloggerPersonalSiteLink={bloggerPersonalSiteLink}
    />
  ) : (
    <BloggerAuthorProfile />
  );
}

export default DescriptionAuthor;
