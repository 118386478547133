import React, { useContext } from "react";
import Title from "../components/Title";
import Career from "../components/Career";
import careers from "../constant/careers";
import { blogContext } from "../App";
import Loading from "../components/Loading";

function Careers({ title }) {
  const { loading } = useContext(blogContext);
  if (loading) {
    return <Loading title=" all careers paths are currently loading ..." />;
  }
  return (
    <section className="section">
      <Title title={title} specificTitle />

      <div className="section-career">
        {careers.map((career) => {
          return <Career key={career.id} {...career} />;
        })}
      </div>
    </section>
  );
}

export default Careers;
