import React, { useContext, useState } from "react";
import SingleParentComment from "../components/SingleParentComment";
// import EditSingleParentComment from "./EditSingleParentComment";
import { blogContext } from "../App";
import axios from "axios";

function ParentComments({ title, slug, category, type }) {
  let { comments, setComment } = useContext(blogContext);
  const [isPostDeleted, isSetPostDeleted] = useState(false);

  const handlePostDeleted = () => {
    isSetPostDeleted((prev) => {
      return !prev;
    });
  };
  comments = comments
    .filter(
      (comment) =>
        comment.title === title &&
        comment.category === category &&
        comment.parentId === null &&
        comment.type === type
    )
    .sort((a, b) => {
      return (
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      );
    });

  const handleDeleteComment = async (id) => {
    await axios.delete(`http://localhost:1337/comments/${id}`).then(() => {
      axios
        .get("http://localhost:1337/comments")
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          setComment(data);
          handlePostDeleted();
        })
        .catch(function (error) {
          return error.message;
        });
    });
    await handlePostDeleted();
  };

  return comments.map((comment) => {
    return (
      <>
        <SingleParentComment
          slug={slug}
          key={comment.id}
          title={title}
          {...comment}
          handleDeleteComment={() => handleDeleteComment(comment.id)}
          isPostDeleted={isPostDeleted}
          handlePostDeleted={handlePostDeleted}
          type="comment"
        />
      </>
    );
  });
}

export default ParentComments;
