import React from "react";
import Title from "../components/Title";
import Video from "../components/Video";
import { Link } from "react-router-dom";

const Services = (props) => {
  const { services } = props;
  return (
    <section className="section bg-grey">
      <Title title="What is HackersDev" specificTitle />
      <p className="introduction">
        Hi everyone 👋, <br></br>
        Welcome to HackersDev platform! HackersDev is a fantastic place for
        software engineers and cybersecurity experts. It's a one-stop platform
        to empower you and make a difference in the world.<br></br>
        Here on this site, we share various types of blogs and technical
        programming tutorials on both software engineering and cyber security.
        We provide the best resources for aspiring cybersecurity and developers
        to succeed. You can also access free live coding projects and explore
        all our GitHub repositories. Learn from successful startup founders and
        get your portfolio reviewed by experienced experts.<br></br>
        We're here to help you find an excellent mentor to speed up your
        development journey. Plus, we have a chat and community group where
        developers can connect and communicate. This helps strengthen our
        community.
        <br></br> Whether you're starting from scratch in programming or working
        on creating full-stack applications, we're here for you. If you're
        self-taught, a bootcamp graduate, or a computer science student looking
        to level up your skills, we offer different options like free signup,
        monthly payment, and yearly payment. Moreover, we share stories from
        developers and cybersecurity engineers to keep you motivated while you
        learn and enhance your skills. Our platform also enables companies to
        post job opportunities. Additionally, our developer community includes
        successful entrepreneurs, coders, and non-coders who share their stories
        of successfully launching their startups. Are you ready to elevate your
        life? Cybersecurity and software engineering are the future of this era.
        Join us and be part of it!
      </p>
      <Video url="-hzr2ULrR4A" showPopUp="showPopUp" imageWindow="img-window" />

      <Title title="all  our resources" specificTitle />
      <div className="section-center services-center section-center-home-page">
        {services.map((service) => {
          const { id, icon, title, text, slug } = service;
          return (
            <article key={id} className="service-card">
              {icon}
              <h4>{title}</h4>
              <div className="underline"></div>
              <p>{text}</p>
              {<Link to={`tutorials/${slug}`}>learn more</Link>}
            </article>
          );
        })}
      </div>
    </section>
  );
};

export default Services;
