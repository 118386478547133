import React from "react";
import { Link } from "react-router-dom";

const Blog = (props) => {
  const { slug, title, titleImage, introductionDescription, id } = props;

  const CutDownWord = (title) => {
    let newWord = title.substring(0, 120);
    return newWord;
  };

  return (
    <Link key={id} to={`/blogs/${slug}`} className="article-link blog">
      <article className="blog-article">
        <div className="container-blog">
          <div className="container-subtitle">
            <h1>{title}</h1>
          </div>
          <div className="image-description">
            <div className="container-image">
              <img src={`${titleImage.url}`} alt="why coding" />
            </div>

            <p className="description">
              {CutDownWord(introductionDescription)}
              <span> ...read all</span>
            </p>
          </div>
        </div>
      </article>
    </Link>
  );
};

export default Blog;
