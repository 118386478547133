import React, { useState } from "react";
import { RiTeamFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { FaConnectdevelop, FaBlogger, FaBlog } from "react-icons/fa";

import uuid from "react-uuid";
function Slider({ showSlider, isOpen, handleButton }) {
  const data = [
    {
      id: uuid(),
      Icon: FaConnectdevelop,
      description: "WHAT IS HACKERSDEV",
      path: "/",
    },

    {
      id: uuid(),
      Icon: FaBlogger,
      description: "Explore All Blogs",
      path: "/blogs/",
    },

    {
      id: uuid(),
      Icon: FaBlog,
      description: "Explore All Tutorials",
      path: "/tutorial/",
    },
    {
      id: uuid(),
      Icon: RiTeamFill,
      description: "Meet The Team",
      path: "/team/",
    },
  ];
  const [value, setValue] = useState(0);
  const body = document.body;

  const allServices = (data) => {
    return data.map((item, index) => {
      const { id, Icon, description, path } = item;
      return (
        <li
          onClick={() => setValue(index)}
          className={`${index === value && "active-btn"}`}
          key={id}
        >
          <Link to={path}>
            {<Icon className="nav-icon nav-icon-logo" />}
            <span>{description}</span>
          </Link>
        </li>
      );
    });
  };

  return (
    <div
      className={
        isOpen
          ? `   slider ${showSlider}  ${(body.style.overflow = "hidden")} `
          : ` slider ${(body.style.overflow = "visible")}`
      }
    >
      <div className="slider-container">
        <div className="btn-slide-right" onClick={handleButton}>
          &times;
        </div>

        <div className="nav-header">
          <a href="/" className="container-logo">
            <div className="container-logo-symbol">
              <span>HD</span>
            </div>
            <div className="container-logo-title">
              <span>HackersDev</span>
            </div>
          </a>
        </div>
        <div className="container-all-content">
          <ul>{allServices(data)}</ul>
        </div>
      </div>
    </div>
  );
}

export default Slider;
