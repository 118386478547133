import React from "react";

import { Helmet } from "react-helmet-async";

const Seo = ({ seoData }) => {
  return (
    seoData != null && (
      <Helmet>
        <link rel="canonical" href={seoData.canonical} />
        <meta name="description" content={seoData.metaDescription} />
        <meta name="keywords" content={seoData.metaKeywords} />
        <meta name="viewport" content={seoData.viewport} />
        <meta name="robots" content={seoData.robots} />
        <meta name="author" content={seoData.author} />
        <meta name="language" content={seoData.language} />
        <meta property="og:title" content={seoData.ogTitle} />
        <meta property="og:type" content={seoData.ogType} />
        <meta property="og:description" content={seoData.ogDescription} />
        <meta property="og:image" content={seoData.ogImage} />
        <meta property="og:url" content={seoData.ogUrl} />
        <meta name="twitter:card" content={seoData.twitterCard} />
        <meta name="twitter:site" content={seoData.twitterSite} />
        <meta property="twitter:title" content={seoData.twitterTitle} />
        <meta name="twitter:description" content={seoData.twitterDescription} />
        <meta name="twitter:image" content={seoData.twitterImage} />
        <script type="application/ld+json">
          {JSON.stringify(seoData.structuredData)}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(seoData.singlePostStructuredData)}
        </script>
      </Helmet>
    )
  );
};

export default Seo;
