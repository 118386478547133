import React, { useEffect } from "react";
import Links from "../constant/Links";
import { FaAlignJustify } from "react-icons/fa";
import { useState } from "react";
import Slider from "../components/Slider";
import Authentification from "../components/Authentification";

import BtnSignDesktop from "../components/BtnSignDesktop";
import { useAuth0 } from "@auth0/auth0-react";
import Warning from "../components/Warning";

import uuid from "react-uuid";

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  /*  const [showWarning, setShowWarning] = useState(false); */
  const [slideRight, setSlideRight] = useState(false);
  const [navbar, setNavbar] = useState(false);
  const { isAuthenticated, logout, user } = useAuth0();

  const handleVisibility = () => {
    setVisible((prev) => {
      return !prev;
    });
  };
  useEffect(() => {
    if (visible) {
      window.addEventListener("click", handleVisibility);
      return () => {
        window.removeEventListener("click", handleVisibility);
      };
    }
  }, [visible]);
  const changeNavbar = () => {
    if (window.scrollY >= 100 && window.scrollY <= 320) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", changeNavbar);

  const handleButton = () => {
    setIsOpen((prev) => {
      return !prev;
    });
  };
  return (
    <>
      <div className="body-navbar">
        <div className="container-navBar">
          <nav className={navbar ? "navbar " : "navbar active-navbar"}>
            <div className="nav-center">
              <Authentification
                slideRight={slideRight}
                logout={logout}
                user={user}
                isAuthenticated={isAuthenticated}
                visible={visible}
                handleVisiblity={handleVisibility}
              />

              <Slider
                showSlider="showSlider"
                isOpen={isOpen}
                handleButton={handleButton}
              />
              <button type="button" className="nav-btn " onClick={handleButton}>
                <FaAlignJustify className="nav-icon" />
              </button>
              <div className="nav-header">
                <a href="/" className="container-logo">
                  <div className="container-logo-symbol">
                    <span>HD</span>
                  </div>
                  <div className="container-logo-title">
                    <span>HackersDev</span>
                  </div>
                </a>
              </div>
              <Links />
              <div>
                <BtnSignDesktop
                  visible={visible}
                  handleVisible={handleVisibility}
                />
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

export default NavBar;
