import React, { useState, useEffect } from "react";

import Hero from "../components/Hero";

import Banner from "../components/Banner";
import Blogs from "../components/Blogs";
import Tutorials from "../components/Tutorials.js";

import Services from "../components/Services.js";
import services from "../constant/services";
import price from "../constant/price";
import Responsive from "../components/Responsive";
import Goals from "../components/Goals";
import Companies from "../components/Companies";
import Layout from "../components/Layout";
import Prices from "../components/Prices";
import Careers from "../components/Careers";
import Objectifs from "../components/Objectifs";

import Seo from "../components/Seo.js";

const Home = () => {
  const initialValues = {
    email: "",
  };
  const [subscription, setSubscription] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [userEmail, setUserEmail] = useState({});
  const [error, setError] = useState(null);
  const [signed, setSigned] = useState("");

  const handleChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;

    setSubscription({ ...subscription, [name]: value });
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
    }
  }, [formErrors]);

  const validate = (users) => {
    const errors = {};

    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!users.email) {
      errors.email = "Email is required!!!";
    } else if (!regex.test(users.email)) {
      errors.email = "This is not the valid email format!";
    }

    return errors;
  };
  const [visibility, setVisibility] = useState(false);

  const handleVisibility = () => {
    setVisibility((prev) => {
      return !prev;
    });
  };

  const handleSubscription = async (e) => {
    e.preventDefault();
    setError(null);
    setFormErrors(validate(subscription));

    setIsSubmit(true);

    let email = subscription.email;
    const api_url = `https://backend.ratemymajor.io/api/memberAdd/${email}`;
    // let email = subscription.email;
    // const api_url = `/api/memberAdd/${email}`;

    fetch(api_url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (data.title === "Member Exists") {
          setSigned("You are already signed up for this email  😥 service.");
        } else if (data.title === "") {
          setSubscription({
            email: "",
            subscribe: "",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);

        if (
          error instanceof Response &&
          (error.status === 400 || error.status === 500)
        ) {
          setError(
            "An error occurred with the server. Please try again later."
          );
        } else if (error.message === "Network response was not ok") {
          setError(
            "It could be a  network error. Please check your internet connection."
          );
        } else {
          setError("An unknown error occurred. Please try again later.");
        }
      });
  };
  return (
    <>
      <Seo />
      <Layout visible={visibility} handleVisible={handleVisibility}>
        <Hero hero="defaultHero" videobackground>
          <Banner
            title="Social sharing platform for Software Engineers and Cybersecurity experts"
            subtitle="Join a community where software engineers and cybersecurity experts come together to master data modeling, development 💻, and protection 🥷🔒 - all for free! Enjoy the freedom to share your thoughts and insights on everything tech."
          >
            <form onSubmit={handleSubscription}>
              {Object.keys(userEmail).length !== 0 ? (
                <div className="successReview">{userEmail.message}</div>
              ) : (
                Object.keys(formErrors).length === 0 &&
                isSubmit && (
                  <>
                    <h6
                      style={{
                        textAlign: "center",
                        color: "#fff",
                        fontWeight: "800",
                      }}
                      // className="successReview"
                    >
                      {signed.length > 0
                        ? signed
                        : "Thanks 😊 , We just added you on our mailing list"}
                    </h6>
                  </>
                )
              )}
              <span style={{ display: "block", fontWeight: "900" }}>
                Power Of #SofwareEngineering and #CyberSecurity{" "}
              </span>
              <input
                style={{
                  height: "40px",
                  outline: "none",
                  fontSize: "16px",
                  width: "250px",
                  maxWidth: "50%",
                  padding: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  textIndent: "10px",
                }}
                name="email"
                placeholder="Enter your email 📧 ..."
                type="email"
                value={subscription.email}
                onChange={handleChange}
              />
              <button className="btn-primary" type="submit">
                Join Today
              </button>
              <h6
                style={{
                  fontWeight: "800",
                  color: "#fff",
                }}
              >
                {" "}
                {formErrors.email}
              </h6>
              <h6
                style={{
                  fontWeight: "800",
                  color: "#fff",
                }}
              >
                {" "}
                {error}
              </h6>
            </form>
          </Banner>
        </Hero>
        {/* <Signup visible={visibility} handleVisible={handleVisibility} /> */}
        <Services services={services} />
        <Careers title="All Careers paths to success  " />
        <Objectifs title="The 4 Steps to Make You Succeed!" />
        {/*  <AffiliateDescription
          CoursePrep={CoursePrep}
          InterviewPrep={InterviewPrep}
          title="All our affiliates programs"
        /> */}
        <Blogs title="Best Trending blog" showLink />
        {/* <Team title="Your instructor" showLink /> */}
        <Tutorials title="Best practical Tutorials" showLink />
        {/* <Projects title="project" showLink /> */}
        <Goals title="Can you convert a psd file into html ?" />
        {/* <Projects title="All Project" showLink /> */}
        <Responsive title="why responsive web matter?" />
        <Companies />
        {/* <Team title="Your instructor" showLink /> */}
        {/* <Entrepreneurs home title="Successful entrepreneurs" showLink /> */}
        {/* <Affiliates home title="Access all products" showLink />
        <Mentorship home title="1:1 Mentoring programm" showLink /> */}
        {/* <Interviews home title="access interview preparation " showLink />" */}
        {/* <Testimonials
          home
          testimonials={testimonials}
          showLink
          translate="translate"
        /> */}
        <Prices home title="Our Price" prices={price} />
      </Layout>
    </>
  );
};
export default Home;
