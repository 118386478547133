import React from "react";
import uuid from "react-uuid";
import { Link } from "react-router-dom";

const data = [
  {
    id: uuid(),
    link: "/",
    name: "HOME 🏡",
    icon: "",
  },
  {
    id: uuid(),
    link: "/blogs",
    name: "BLOGS🧠",
    icon: "",
  },
  {
    id: uuid(),
    link: "/tutorials",
    name: "TECH📚✍️ TUTORIALS",
    icon: "",
  },

  {
    id: uuid(),
    link: "/about",
    name: "  FOUNDER 👨🏻‍💻",
    icon: "",
  },
];
const Links = () => {
  return (
    <div className="container-nav-links">
      <ul className="nav-links">
        {data.map((item) => {
          return (
            <li key={uuid()}>
              <Link to={item.link}>
                <span>
                  {item.name}
                  {item.icon}
                </span>
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Links;
