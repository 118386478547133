import React from "react";
import {
  FacebookButton,
  LinkedInButton,
  TwitterButton,
  RedditButton,
  PinterestButton,
  FacebookCount,
  LinkedInCount,
  TwitterCount,
  RedditCount,
  PinterestCount,
} from "react-social";
import facebook from "../assets/SociauMedia/facebook.jpg";
import twitter from "../assets/SociauMedia/twitter.jpg";

import linkedin from "../assets/SociauMedia/linkedin.jpg";
import reddit from "../assets/SociauMedia/reddit.jpg";
import pinterest from "../assets/SociauMedia/pinterest.png";

function Share({ description, slug }) {
  slug = "coding";

  let url = window.location.href;

  return (
    <div className="big-container-social-share">
      <p>{description}</p>
      <div className="container-social-share">
        <FacebookButton
          sharer={true}
          url={url}
          quote="best way to learn coding"
          appId={1359370807859748}
        >
          <div className="container-btn-share">
            <img src={facebook} />
          </div>
        </FacebookButton>
        <TwitterButton url={url}>
          <div className="container-btn-share">
            <img src={twitter} />
          </div>
        </TwitterButton>
        <LinkedInButton url={url}>
          <div className="container-btn-share">
            <img src={linkedin} />
          </div>
        </LinkedInButton>
        <RedditButton url={url}>
          <div className="container-btn-share">
            <img src={reddit} />
          </div>
        </RedditButton>
        <PinterestButton url={url}>
          <div className="container-btn-share">
            <img src={pinterest} />
          </div>
        </PinterestButton>
      </div>
    </div>
  );
}

export default Share;
