import React, { useContext } from "react";
import Title from "../components/Title";
import Objectif from "../components/Objectif";
import { blogContext } from "../App";
import Loading from "../components/Loading";
import objectifs from "../constant/Objectifs";

function Objectifs({ title }) {
  const { loading } = useContext(blogContext);
  if (loading) {
    return <Loading title=" all videos are currently loading ..." />;
  }
  return (
    <section className="section ">
      <Title title={title} specificTitle />
      <div className="container-specific-objectif-webdevadvice">
        {/* <p>
          Discover How Hundreds of Students Have Learned Enough at Hackersdev
          and they were able to :{" "}
        </p> */}
        <p
          style={{
            width: "1200px",
            maxWidth: "95%",
            textAlign: "left",
            margin: "2rem auto",
          }}
        >
          Discover How Hundreds of Engineers involve a strategic combination of
          setting{" "}
          <span style={{ color: "#fff", fontWeight: "bold" }}>clear goals</span>
          ,{" "}
          <span style={{ color: "#fff", fontWeight: "bold" }}>
            continuous learning
          </span>
          ,
          <span style={{ color: "#fff", fontWeight: "bold" }}>
            {" "}
            practical skill application
          </span>
          , and{" "}
          <span style={{ color: "#fff", fontWeight: "bold" }}>
            network building
          </span>
          . Following these steps can guide individuals toward their career
          aspirations by providing a structured framework for personal and
          professional growth.
        </p>
      </div>

      {/* <div className="container-specific-objectif-webdevadvice">
        <ul>
          <li> Double Their Income.</li>
          <li>Take their Coding Skills to Build Impressive Portfolio. </li>
          <li>Quit Their Dead- end Jobs.</li>
          <li>Spark High-Paying Tech Careers.</li>
        </ul>
      </div> */}

      <div className="section-career">
        {objectifs.map((objectif) => {
          return <Objectif key={objectif.id} {...objectif} />;
        })}
      </div>
    </section>
  );
}

export default Objectifs;
