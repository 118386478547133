import React, { useState, useContext, useEffect } from "react";

function Email(props) {
  const { description1, description2 } = props;
  const initialValues = {
    email: "",
    subscribe: false,
  };
  const [subscription, setSubscription] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [userEmail, setUserEmail] = useState({});
  const [error, setError] = useState(null);
  const [signed, setSigned] = useState("");

  const handleChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;

    setSubscription({ ...subscription, [name]: value });
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
    }
  }, [formErrors]);

  const validate = (users) => {
    const errors = {};

    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!users.email) {
      errors.email = "Email is required!!!";
    } else if (!regex.test(users.email)) {
      errors.email = "This is not the valid email format!";
    }
    if (users.subscribe === false && !users.email) {
      errors.subscribe = "you aren't filled out email Yet 😟 ";
    }

    return errors;
  };

  const handleSubscription = async (e) => {
    e.preventDefault();
    setError(null);
    setFormErrors(validate(subscription));

    setIsSubmit(true);

    // let email = subscription.email;
    // const api_url = `/api/memberAdd/${email}`;
    let email = subscription.email;
    const api_url = `https://backend.ratemymajor.io/api/memberAdd/${email}`;

    fetch(api_url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (data.title === "Member Exists") {
          setSigned("You are already signed up for this email  😥 service.");
        } else if (data.title === "") {
          setSubscription({
            email: "",
            subscribe: "",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);

        if (
          error instanceof Response &&
          (error.status === 400 || error.status === 500)
        ) {
          setError(
            "An error occurred with the server. Please try again later."
          );
        } else if (error.message === "Network response was not ok") {
          setError(
            "It could be a network error. Please check your internet connection."
          );
        } else {
          setError("An unknown error occurred. Please try again later.");
        }
      });
  };

  return (
    <div className="full-container-jobsListing-jobPosts-email-subscription__jobslisting-email">
      <h5>Subscribe to our mailing list</h5>
      {description1 ? <p style={{ color: "black" }}>{description1}</p> : ""}
      {description2 ? <p style={{ color: "black" }}>{description2}</p> : ""}

      {Object.keys(userEmail).length !== 0 ? (
        <div className="successReview">{userEmail.message}</div>
      ) : (
        Object.keys(formErrors).length === 0 &&
        isSubmit && (
          <>
            <div className="successReview">
              {signed.length ===
              "You are already signed up for this email  😥 service."
                ? signed
                : "Thanks 😊 , We just added you on our mailing list"}
            </div>
          </>
        )
      )}

      <form onSubmit={handleSubscription}>
        <span
          style={{
            display: "block",
            fontWeight: 900,
            color: "var(--primaryColor)",
          }}
        >
          Join our waiting list and be the first to be notified about our
          upcoming platform 📧
        </span>

        {Object.keys(userEmail).length !== 0 ? (
          <div className="successReview">{userEmail.message}</div>
        ) : (
          Object.keys(formErrors).length === 0 &&
          isSubmit &&
          (signed.length > 0 && signed ? (
            <h6
              style={{
                fontWeight: 900,
                color: "red",
              }}
            >
              Your email already existed 😥!!!
            </h6>
          ) : (
            <h6
              style={{
                fontWeight: 900,
                color: "var(--primaryColor)",
              }}
            >
              Thanks 😊 , We just added you on our mailing list
            </h6>
          ))
        )}
        <input
          style={{
            height: "40px",
            outline: "none",
            fontSize: "16px",
            width: "250px",
            maxWidth: "50%",
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "5px",
            textIndent: "10px",
          }}
          name="email"
          placeholder="Enter your email 📧 ..."
          type="email"
          value={subscription.email}
          onChange={handleChange}
        />
        <button className="btn-primary" type="submit">
          Join Today
        </button>
        <h6 style={{ fontWeight: "800", color: "red" }}>{formErrors.email}</h6>
        <h6 style={{ fontWeight: "800", color: "red" }}>{error}</h6>
      </form>
    </div>
  );
}

export default Email;
