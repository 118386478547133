import React from "react";
import Title from "../components/Title";
import amazon from "../assets/companyLogo/amazon.svg";
import facebook from "../assets/companyLogo/facebook.svg";
import google from "../assets/companyLogo/google.svg";
import microsoft from "../assets/companyLogo/microsoft.svg";
import youtube from "../assets/companyLogo/youtube.svg";
import bloomberg from "../assets/companyLogo/bloomberg.svg";
import fbi from "../assets/companyLogo/fbi.svg";
import dnata from "../assets/companyLogo/dnata.svg";
import hp from "../assets/companyLogo/hp.svg";
import nasa from "../assets/companyLogo/nasa.svg";
import nbc from "../assets/companyLogo/nbc.svg";
import netflix from "../assets/companyLogo/netflix.svg";
import zillow from "../assets/companyLogo/zillow.svg";
import twitter from "../assets/companyLogo/twitter.svg";
import instagram from "../assets/companyLogo/instagram.svg";

const Companies = () => {
  return (
    <section className="section  ">
      <Title title="with great outcomes" specificTitle />
      <h4
        style={{
          fontWeight: 800,
          fontSize: "1.5rem",
          margin: "1.2rem 0",
        }}
      >
        Our customers have gotten offers from awesome companies all over the
        world
      </h4>
      <div className="section-companies">
        {/* <Title title="with great outcomes" specificTitle />
        <h4>our customers have gotten offers from awesome companies</h4> */}
        <article className="companies-article">
          <div className="companies-container-side companies-container-side1"></div>
          <div className="companies-container-side companies-container-side2"></div>
          <div className="companies-article-container">
            <div className="companies-container">
              <div>
                <div className=" container-logo-company container-logo1-companies">
                  <div>
                    <img
                      src={amazon}
                      className="opacity"
                      alt="amazon logo"
                      loading="lazy"
                    />
                  </div>
                  <div>
                    <img
                      src={facebook}
                      className="opacity"
                      alt="facebook logo"
                      loading="lazy"
                    />
                  </div>
                  <div>
                    <img
                      src={google}
                      className="opacity"
                      alt="google logo"
                      loading="lazy"
                    />
                  </div>
                  <div>
                    <img
                      src={bloomberg}
                      className="opacity"
                      alt="bloomberg logo"
                      loading="lazy"
                    />
                  </div>
                  <div>
                    <img
                      src={fbi}
                      className="opacity"
                      alt="fbi"
                      loading="lazy"
                    />
                  </div>

                  <div>
                    <img
                      src={youtube}
                      alt="youtube logo"
                      className="opacity"
                      loading="lazy"
                    />
                  </div>
                  <div>
                    <img
                      src={microsoft}
                      className="opacity"
                      alt="microsoft logo"
                      loading="lazy"
                    />
                  </div>
                </div>
                <div className=" container-logo-company container-logo2-companies">
                  <div>
                    <img
                      src={amazon}
                      alt="amazon logo"
                      className="opacity"
                      loading="lazy"
                    />
                  </div>
                  <div>
                    <img
                      src={facebook}
                      alt="facebook logo"
                      className="opacity"
                      loading="lazy"
                    />
                  </div>
                  <div>
                    <img
                      src={google}
                      alt="google logo"
                      className="opacity"
                      loading="lazy"
                    />
                  </div>
                  <div>
                    <img
                      src={bloomberg}
                      alt="bloomberg logo"
                      className="opacity"
                      loading="lazy"
                    />
                  </div>
                  <div>
                    <img
                      src={fbi}
                      className="opacity"
                      alt="fbi"
                      loading="lazy"
                    />
                  </div>

                  <div>
                    <img
                      src={youtube}
                      className="opacity"
                      alt="youtube logo"
                      loading="lazy"
                    />
                  </div>
                  <div>
                    <img
                      src={microsoft}
                      className="opacity"
                      alt="microsoft logo"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className=" container-logo-company  container-logo3-companies">
                  <div>
                    <img
                      src={dnata}
                      className="opacity"
                      alt="dnata logo"
                      loading="lazy"
                    />
                  </div>
                  <div>
                    <img
                      src={hp}
                      className="opacity"
                      alt="hp logo"
                      loading="lazy"
                    />
                  </div>
                  <div>
                    <img
                      src={nasa}
                      className="opacity"
                      alt="nasa logo"
                      loading="lazy"
                    />
                  </div>
                  <div>
                    <img
                      src={netflix}
                      className="opacity"
                      alt="netflix logo"
                      loading="lazy"
                    />
                  </div>
                  <div>
                    <img
                      src={nbc}
                      className="opacity"
                      alt="nbc logo"
                      loading="lazy"
                    />
                  </div>
                  <div>
                    <img
                      src={twitter}
                      className="opacity"
                      alt="twitter logo"
                      loading="lazy"
                    />
                  </div>
                  <div>
                    <img
                      src={zillow}
                      className="opacity"
                      alt="zillow logo"
                      loading="lazy"
                    />
                  </div>
                </div>
                <div className=" container-logo-company  container-logo4-companies">
                  <div>
                    <img
                      src={dnata}
                      className="opacity"
                      alt="dnata logo"
                      loading="lazy"
                    />
                  </div>
                  <div>
                    <img
                      src={hp}
                      className="opacity"
                      alt="hp logo"
                      loading="lazy"
                    />
                  </div>
                  <div>
                    <img
                      src={nasa}
                      className="opacity"
                      alt="nasa logo"
                      loading="lazy"
                    />
                  </div>
                  <div>
                    <img
                      src={netflix}
                      className="opacity"
                      alt="netflix logo"
                      loading="lazy"
                    />
                  </div>
                  <div>
                    <img
                      src={nbc}
                      className="opacity"
                      alt="nbc logo"
                      loading="lazy"
                    />
                  </div>
                  <div>
                    <img
                      src={twitter}
                      className="opacity"
                      alt="twitter logo"
                      loading="lazy"
                    />
                  </div>
                  <div>
                    <img
                      src={zillow}
                      className="opacity"
                      alt="zillow logo"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>
    </section>
  );
};

export default Companies;
