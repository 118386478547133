import React from "react";
import { Link } from "react-router-dom";

function Disclosure() {
  const handleClick = (event) => {
    event.preventDefault();
  };
  return (
    <div className="global-disclosure-small-affiliate-section">
      <div className="global-disclosure-small-affiliate-section-inner-container">
        <p>
          <strong>Disclosure:</strong>
          Some of the links in this article may be affiliate links, which can
          provide compensation to me at no cost to you if you decide to purchase
          a paid plan. These are products I’ve personally used and stand behind.
          This site is not intended to provide financial advice and is for
          entertainment only. You can read our affiliate disclosure in our{" "}
          <Link to="" onClick={handleClick}>
            {" "}
            privacy policy
          </Link>
          .
        </p>
      </div>
    </div>
  );
}

export default Disclosure;
