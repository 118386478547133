import React from "react";

import { Link } from "react-router-dom";

export default function Price(props) {
  const { title, price, info, id } = props;
  const infos = () => {
    return info.map((item) => {
      const { title, icon, id } = item;
      return (
        <div key={id} className="container-title-icon">
          <span>{icon}</span>
          <span>{title}</span>
        </div>
      );
    });
  };
  return (
    <article className="blog-article price">
      <div className="container-price">
        <div className="container-title-sign-price">
          <span className="sub-container-title"> {title}</span>
          <span className="sub-container-price">
            <span>{price}</span>
          </span>
        </div>

        {infos()}
      </div>
      {id >= 1 ? (
        <Link
          to=""
          title="sorry, the content is not available yet"
          className=" center-btn btn-primary disabled "
        >
          signup here
        </Link>
      ) : (
        <Link to="" className=" center-btn btn-primary  ">
          signup for free
        </Link>
      )}
    </article>
  );
}
