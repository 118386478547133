import uuid from "react-uuid";
import goal from "../assets/career_path/steps_developer/step_goals.jpg";
import learning from "../assets/career_path/steps_developer/step_continuous_learning.jpg";
import project from "../assets/career_path/steps_developer/step_real_project.jpg";
import meetup from "../assets/career_path/steps_developer/step_meetup.jpg";

const objectifs = [
  {
    id: uuid(),
    title:
      "Set Clear Goals 🥅: Define specific achievements you want to reach in your career journey.",
    image: goal,
    alt: "Set Clear Goals.",
  },
  {
    id: uuid(),
    title:
      "Continuous Learning: Keep updating your skills and knowledge to stay relevant in a rapidly evolving field. ",
    image: learning,
    alt: "Continous learning ",
  },
  {
    id: uuid(),
    title:
      "Practical Application: Apply what you learn through hands-on projects to gain valuable experience. ",
    image: project,
    alt: "Clear  goals to success",
  },
  {
    id: uuid(),
    title:
      "Network Building: Connect with professionals in the industry to learn, collaborate, and access opportunities.",
    image: meetup,
    alt: "Network Building ",
  },
];
export default objectifs;
