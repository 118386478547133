import React from "react";
import { useHistory } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import App from "./App";

const Auth0ProviderWithHistory = () => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

  const history = useHistory();

  const onRedirectCallback = (appState) => {
    const redirectPath = sessionStorage.getItem("redirectPath") || "/";
    history.push(
      appState && appState.targetUrl ? appState.targetUrl : redirectPath
    );
    sessionStorage.removeItem("redirectPath");
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
      cacheLocation="localstorage"
    >
      <App />
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
