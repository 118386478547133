import styled from "styled-components";
const StyledHero = styled.header`
  min-height: calc(50vh - 100px);
  background: url(${({ img }) => img});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  place-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
`;
export default StyledHero;
